import request from './request'

const menu = {
    postLogin(params) {
        return request({
            method: 'POST',
            url: '/login-center/platform/pc/login',
            data: params
        })
    },
    // 退出登录
    logout(params) {
        return request({
            method: 'POST',
            url: '/login-center/platform/pc/logout',
            data: params
        })
    },
    //首页订单列表(回收商)
    HomeOrderList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/platformOrderMerchantRankList',
            data: params,
        })
    },

    //首页订单列表(门店商)
    HomeOrderListMen(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/platformOrderCompanyRankList',
            data: params,
        })
    },
    //商户借款分布-合作商户借款分布
    findMerchantLoanAmountSpreadDown(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/platformFinance/statistics/findMerchantLoanAmountSpreadDown',
            data: params,
        })
    },
    //商户借款分布
    findMerchantLoanAmountSpreadThan(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/platformFinance/statistics/findMerchantLoanAmountSpreadThan',
            data: params,
        })
    },
    //品牌价位段统计
    brandPriceRankList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/brandOrPriceRankStatistics',
            data: params,
        })
    },
    //品牌价位段统计--商家/门店商
    brandPriceRankDown(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/brandOrPriceRankStatisticsDown',
            data: params,
        })
    },
    //导出-利润排行榜【品牌&价格段】
    brandOrPriceRankStatisticsExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/brandOrPriceRankStatisticsExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // 利润排行榜【机型】
    machineRankStatistics(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/machineRankStatistics',
            data: params,
        })
    },
    // 利润排行榜【机型】-报价分布
    machineRankStatisticsQuote(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/machineRankStatisticsQuote',
            data: params,
        })
    },
    // 导出-利润排行榜【机型】
    machineRankStatisticsExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/machineRankStatisticsExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // 利润排行榜【机型】-询价分布
    machineRankStatisticsInquiry(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/machineRankStatisticsInquiry',
            data: params,
        })
    },
    // 利润排行榜【机型】-订单
    machineRankStatisticsOrderList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/machineRankStatisticsOrderList',
            data: params,
        })
    },
    // 利润排行榜【机型】-订单
    getMerchantRankStaffDealStatistics(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/getMerchantRankStaffDealStatistics',
            data: params,
        })
    },
    //审核记录
    auditRecord(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr/auditRecord',
            data: params,
        })
    },
    //添加部署
    addSlefcompany(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/dep/add',
            data: params,
        })
    },
    //部署-列表
    getslefBdepdatalist(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/dep/list',
            data: params,
        })
    },
    //部署-启用-禁用
    getslefsetEnable(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/dep/setEnable',
            data: params,
        })
    },
    //部署-门店后台首页配置
    setStorePCConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/dep/storePCConfig',
            data: params,
        })
    },
    //编辑部署-门店后台首页配置
    editStorePCConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/dep/editInfo',
            data: params,
        })
    },
    //部署-所有资源配置
    getStaticResource(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/dep/staticResource/list',
            data: params,
        })
    },
    //部署-修改资源所有资源配置
    updateStaticResource(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/dep/staticResource/update',
            data: params,
        })
    },
    //部署-Q-详情
    getindependentinfo(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/dep/info',
            data: params,
        })
    },
    //渠道首页订单列表
    cancleHomeOrderList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/agentStatistics/agentOrderRankList',
            data: params,
        })
    },
    //分转商家列表启用禁用关系
    enableMiddle(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/enableMiddle',
            data: params,
        })
    },
    //分转商家列表启用禁用关系
    updateMiddle(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/updateMiddle',
            data: params,
        })
    },
    //分转商家列表列表
    getMiddleList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/getMiddleList',
            data: params,
        })
    },
    //编辑短信
    editshortMessage(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/dep/edit',
            data: params,
        })
    },
    //分转商家详情
    getMiddleInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/dptm/getMiddleInfo',
            params,
        })
    },
    //清理微信信息
    clearWechatInfoByStaff(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/clearWechatInfoByStaff',
            params,
        })
    },
    //查询报价设置列表
    getdownpriceConfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/getQuoteConfig',
            params,
        })
    },
    //恢复已取消订单
    doCancelRestore(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/doCancelRestore',
            params,
        })
    },
    // 导出平台端首页订单交易排行榜
    platformOrderRankListExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/platformOrderMerchantRankListExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // 回收商家统计门店商家和报价师-导表复制接口复制文档复制地址
    merchantDealStatisticsExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/merchantDealStatisticsExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // Q-导出回收商交易排行榜【拒绝】报价单列表Excel
    platformbaoListExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/quote-list-excel',
            data: params,
            responseType: 'blob'
        })
    },
    // 导出平台端首页订单交易排行榜(门店商)
    platformOrderRankListExcelMen(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/platformOrderCompanyRankListExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // 导出渠道端首页订单交易排行榜
    cancleformOrderRankListExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/agentStatistics/platformOrderRankListExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // 获取备注门店商
    getNoteCompany(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/getNoteCompany',
            data: params,
        })
    },
    // 保存报价设置
    saveyajiaConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/saveQuoteConfig',
            data: params,
        })
    },
    // 配置列表 限时抢单设置
    exclusiveList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/limit/exclusive/config/list',
            data: params,
        })
    },
    // 导出分类品牌机型Excel
    importModelByTypeBrand(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/importModelByTypeBrand',
            data: params,
            responseType: 'blob'
        })
    },
    // 保存配置 限时抢单设置
    exclusiveSave(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/limit/exclusive/config/save',
            data: params,
        })
    },
    //是否启用 限时抢单设置
    exclusiveIsEnable(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/limit/exclusive/config/updateIsEnable',
            params,
        })
    },
    //删除 限时抢单设置
    exclusiveRemoveById(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/limit/exclusive/config/removeById',
            params,
        })
    },
    // 备注门店商
    noteCompany(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/noteCompany',
            data: params,
        })
    },
    // 待发货旧机【门店】
    getWaitSendGroupStore(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/getWaitSendGroupStore',
            data: params,
        })
    },
    // 待发货旧机催发货
    remindShipment(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/remindShipment',
            data: params,
        })
    },
    // 待发货旧机【订单】
    getWaitSendOrder(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/getWaitSendOrder',
            data: params,
        })
    },
    // 待发货订单统计
    getWaitSendOStatistics(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/getWaitSendOStatistics',
            data: params,
        })
    },
    //分转商家 Q-导出Excel
    middleExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/middle/excel',
            data: params,
            responseType: 'blob'
        })
    },
    // 门店首页订单列表
    storeHomeOrderList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/excelStatisticsRank',
            data: params,
            responseType: 'blob'
        })
    },
    // 门店订单统计
    storeHomeOrder(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getStatisticsRank',
            data: params,
        })
    },
    //首页数据
    platformDataStatisticsBy(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/statistics/platformDataStatisticsBy',
            params,
        })
    },
    //首页数据
    HomeData(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/platformDataStatistics',
            data: params,
        })
    },
    //平台端首页数据（入驻数据）
    platformJoinMCDataStatistics(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/platformJoinMCDataStatistics',
            data: params,
        })
    },
    //渠道首页数据
    cancleHomeData(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/agentStatistics/agentDataStatistics',
            data: params,
        })
    },
    // 门店首页数据
    storeHomeData(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/dataStatistics',
            data: params,
        })
    },
    //首页图表
    echartsList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/platformStatisticsOrderList',
            data: params,
        })
    },
    //借贷统计折线图
    findLoanAmountStatisticsLine(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/platformFinance/statistics/findLoanAmountStatisticsLine',
            data: params,
        })
    },
    //首页本月订单总数查询 
    MonthDataStatic(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/platformMonthDataStatistics',
            data: params,
        })
    },
    //渠道首页图表
    cancleechartsList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/agentStatistics/agentStatisticsOrderList',
            data: params,
        })
    },
    //回收商家统计门店商家和报价师
    merchantDealStatistics(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/merchantDealStatistics',
            data: params,
        })
    },
    //回收商家统计门店（分页）
    merchantDealByCompany(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/merchantDealByCompanyPageInStore',
            data: params,
        })
    },
    //门店商家成交回收商统计
    companyDealMerchant(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/companyDealMerchant',
            data: params,
        })
    },
    //门店商家询价店员统计（分页）
    companyStaffStatistics(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/companyStaffStatistics',
            data: params,
        })
    },
    // 省市区
    listAudit(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/sys/city/listCityByParent/${params}`,
            params: params,
        })
    },
    //第三方回收
    // 入驻列表
    ApplyTable(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/listAudit',
            data: params,
        })
    },
    //查看审核
    infoAudit(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/recycle/merchant/infoAudit/${params}`,
            params: params,
        })
    },
    // 入驻列表操作
    modifyAuditOper(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/modifyAuditOper',
            data: params,
        })
    },
    // 商家列表
    ShopTable(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/list',
            data: params,
        })
    },
    // 切换收货仓库
    updateMerchantWarehouse(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matrix/updateMerchantWarehouse',
            data: params,
        })
    },
    // 已添加机型列表
    PhoneAddModelTable(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/automatic/otherQuote/config/findAddedMachine',
            data: params,
        })
    },
    // 嗨已添加机型列表
    haiAddModelTable(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/automatic/hi/config/findAddedMachine',
            data: params,
        })
    },
    // 未添加机型列表
    PhoneModelTable(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/automatic/otherQuote/config/findMachine',
            data: params,
        })
    },
    // 嗨未添加机型列表
    haioutModelTable(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/automatic/hi/config/findMachine',
            data: params,
        })
    },
    // 保存配置
    getPhoneModelTable(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/automatic/otherQuote/config/save',
            data: params,
        })
    },
    // 嗨保存配置
    saveHaiModelTable(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/automatic/hi/config/save',
            data: params,
        })
    },
    // 员工列表
    listStaff(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/listStaff',
            data: params,
        })
    },
    // 合作商家列表
    listCompany(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/listCompany',
            data: params,
        })
    },
    // 累计成交订单列表
    recycleMerchantOrderList(params) {
        return request({
            method: 'POST',
            url: 'platform/pc/recycle/merchant/recycleMerchantOrderList',
            data: params,
        })
    },
    // 回收商排序详情
    merchantOrderRankBy(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/merchantOrderRankBy',
            data: params,
        })
    },
    // 清除微信信息
    cleanWechatInfo(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/cleanWechatInfo',
            data: params,
        })
    },
    // 启用停用公众号推送
    openCloseWechatPush(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/openCloseWechatPush',
            data: params,
        })
    },
    // 启用停用记录
    openCloseRecords(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/openCloseRecords',
            data: params,
        })
    },


    // 合作回收商家列表
    recycleChantList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/unionMerchantList',
            data: params,
        })
    },
    // 添加商家
    ShopTableAdd(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/add',
            data: params,
        })
    },
    //查看详情
    ShopTableXQ(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/recycle/merchant/info/${params}`,
            params: params,
        })
    },
    // 编辑商家
    ShopTableEdit(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/modify',
            data: params,
        })
    },
    //商家禁用/启用
    modifyMerchantListOper(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/modifyMerchantListOper',
            data: params,
        })
    },
    //差异订单添加备注
    aprAddNote(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr/addNote',
            data: params,
        })
    },
    //添加备注图片
    aprAddNoteImage(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr/addNoteImage',
            data: params,
        })
    },
    //差异订单备注记录
    noteRecord(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/apr/noteRecord',
            params: params,
        })
    },
    // 是否可联系店员
    updateIsContact(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/updateIsContact',
            data: params,
        })
    },
    //查看详情
    getAllMiddleAdoptCompany(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/recycle/merchant/getAllMiddleAdoptCompany`,
            params: params,
        })
    },
    // 导入线下打款差异
    aprImport(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr/import',
            data: params,
        })
    },
    // 确定导入线下打款差异
    importSave(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr/importSave',
            data: params,
        })
    },
    //门店列表获取报价配置模板
    getQuoteAssignTemp(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/company/getQuoteAssignTemp`,
            params: params,
        })
    },
    //三方回收商列表获取报价配置模板
    getmerchantQuoteAssignTemp(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/recycle/merchant/getQuoteAssignTemp`,
            params: params,
        })
    },
    //批量开启报价
    merchantUnionBatch(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/company/saveMerchantUnionBatch`,
            data: params,
        })
    },
    //批量关闭报价
    closeQuoteBatch(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/company/closeQuoteBatch`,
            data: params,
        })
    },
    //保存合作报价配置
    saveMiddleQuoteConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/saveMiddleQuoteConfig',
            data: params,
        })
    },
    //三方回收商保存合作报价配置
    saveMerchantMiddleQuoteConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/saveMiddleQuoteConfig',
            data: params,
        })
    },
    //是否统一使用报价机型配置
    openDefaultQuote(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/openDefaultQuote',
            data: params,
        })
    },
    //启用-关闭虚拟报价
    openmockQuote(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/oc-mock-quote',
            data: params,
        })
    },
    //报全部机型-仅查看机型
    listByTypeBrand(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/listByTypeBrand',
            data: params,
        })
    },
    //旧机品牌回收
    //列表
    ResidentTable(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/oldBrand/list',
            data: params,
        })
    },
    //编辑
    ResidentEdit(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/oldBrand/update',
            data: params,
        })
    },
    //添加
    ResidentAdd(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/oldBrand/add',
            data: params,
        })
    },
    // 获取查询类目
    getDetectionCategory(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/imei/detection/category/getDetectionCategory',
            params: params,
        })
    },
    // Q-进行查询
    doDetection(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/imei/detection/category/doDetection',
            data,
        })
    },
    // 查询结果查看
    getqueryResults(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/imei/detection/category/queryResults',
            params: params,
        })
    },
    // 查询扣费商户
    getqueryMerchants(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/imei/detection/category/queryMerchants',
            params: params,
        })
    },
    // 下拉列表
    ResidentList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldBrand/selectList',
            params: params,
        })
    },
    // 下拉列表
    oldBrandSelectByType(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldBrand/selectByType',
            params: params,
        })
    },
    // 订单状态下拉列表
    orderStatusList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/orderStateList',
            params: params,
        })
    },
    //是否启用
    ResidentEnable(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldBrand/updateIsEnable',
            params: params,
        })
    },
    //是否热门
    ResidentHot(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldBrand/updateIsHot',
            params: params,
        })
    },
    //修改排序
    ResidentSort(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldBrand/updateSort',
            params: params,
        })
    },
    // /详情
    ResidentInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldBrand/info',
            params: params,
        })
    },


    //示例图片
    //图片示例列表
    sampleImage(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/sampleImage/getListByMachineType',
            data: params,
        })
    },
    //示例图片列表【补充示例图】
    sampleImageSpecial(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/sampleImage/getListByMachineTypeSpecial',
            data: params,
        })
    },
    //新增
    ImageAdd(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/sampleImage/save',
            data: params,
        })
    },
    //保存示例图片【补充示例图】
    ImageAddSpecial(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/sampleImage/saveSpecial',
            data: params,
        })
    },
    // 删除
    imageDelete(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/sampleImage/removeById',
            params: params,
        })
    },
    //排序
    imageSort(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/sampleImage/updateSort',
            params: params,
        })
    },
    // 修改排序【补充示例图】
    imageSortSpecial(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/sampleImage/updateSortSpecial',
            params: params,
        })
    },
    //保存示例图片名称
    saveImageName(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/sampleImage/saveImageName',
            data: params,
        })
    },
    //设置示例图显示配置
    setImageShowConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/sampleImage/setImageShowConfig',
            data: params,
        })
    },
    //设置示例图显示配置【补充示例图】
    setImageShowConfigSpecial(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/sampleImage/setImageShowConfigSpecial',
            data: params,
        })
    },
    //是否上架（补充细节图）
    enableOrDisable(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/sampleImage/enableOrDisable',
            data: params,
        })
    },
    // 保存机型配置（示例图）
    saveMachineConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/sampleImage/saveMachineConfig',
            data: params,
        })
    },
    // 保存机型配置（补充细节图）
    saveMachineSpecialConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/sampleImage/saveMachineSpecialConfig',
            data: params,
        })
    },
    // 保存机型配置（示例图）
    getMachineConfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/sampleImage/getMachineConfig',
            params,
        })
    },
    // 个性配置-Q-获取下拉列表
    getgroupgetSelectListConfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/hideId/failure/group_getSelectList',
            params,
        })
    },
    // 个性配置-详情
    getgroupgetInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/hideId/failure/group_getInfo',
            params,
        })
    },
    // 海报-Q-海报详情
    getposterInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/hideId/failure/poster_get',
            params,
        })
    },
    //个性配置-删除
    removefailurecingfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/hideId/failure/group_remove',
            params,
        })
    },
    //检测项-是否启用
    hideIdupdateIsEnablecingfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/hideId/failure/fault_updateIsEnable',
            params,
        })
    },
     //隐藏ID-获取订单信息
     hideIdgetOrderInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/hideid/getOrderInfo',
            params,
        })
    },
    //检测项-详情
    getonlyhidecingfiginfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/hideId/failure/fault_info',
            params,
        })
    },
    //检测项-修改排序
    hideIdupdateSort(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/hideId/failure/fault_updateSort',
            params,
        })
    },
    // 海报-修改海报
    saveposterIDInfo(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/hideId/failure/poster_save',
            data: params,
        })
    },
     // 快捷备注是否必填的商户列表
     getlistStoreNotRequired(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/remark/listStoreNotRequired',
            data: params,
        })
    },
    // 个性配置-保存机型模板信息）
    getPersonalgroupsave(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/hideId/failure/group_save',
            data: params,
        })
    },
    // 检测项-保存
    getIDfaultsave(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/hideId/failure/fault_save',
            data: params,
        })
    },
    // id隐藏机型-保存
    savemachineID(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/hideId/failure/machine_save',
            data: params,
        })
    },
    // 获取个性配置列表（补充细节图）
    getPersonalConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/sampleImage/getPersonalConfig',
            data: params,
        })
    },
    // 添加个性配置（补充细节图）
    addPersonalConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/sampleImage/addPersonalConfig',
            data: params,
        })
    },
    // 首页广告轮播图管理
    // 列表
    adList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/ad/list',
            data: params,
        })
    },
    // 添加
    adAdd(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/ad/add',
            data: params,
        })
    },
    // 编辑
    adEdit(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/ad/edit',
            data: params,
        })
    },
    // 排序
    adSort(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/ad/sort',
            data: params,
        })
    },
    // 配置
    adSetConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/ad/setConfig',
            data: params,
        })
    },
    // 获取广告内容
    adContent(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/ad/content',
            data: params,
        })
    },
    //回收故障管理列表
    listFault(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/fault/listFault',
            data: params,
        })
    },
    //删除类型
    removeFault(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/fault/removeFault',
            params: params,
        })
    },
    //添加类型
    addFault(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/fault/addFault',
            data: params,
        })
    },
    //编辑类型
    editFault(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/fault/editFault',
            data: params,
        })
    },

    //回收渠道商下拉
    listSimpleMerchantView(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/listSimpleMerchantView',
            data: params,
        })
    },
    //收货方下拉列表
    listDptmMerchantList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/listDptmMerchantList',
            data: params,
        })
    },
    //计算金额
    merchantCalc(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/merchant/calc',
            data: params,
        })
    },
    //计算金额
    calcRecharge(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/merchant/calcRecharge',
            data: params,
        })
    },
    //下账次数
    minusFrequency(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/merchant/minusFrequency',
            data: params,
        })
    },
    //回收渠道商标签下拉列表
    getAssignPcAccountSelectModel(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/recycle/merchant/getAssignPcAccountSelectModel',
            params: params,
        })
    },
    //合同类型下拉列表
    gettypeList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/contract/typeList',
            params: params,
        })
    },
    //门店商家下拉列表-添加黑名单 
    getSelectListInBlacklist(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/getSelectListInBlacklist',
            params: params,
        })
    },
    //获取商家账户信息-添加黑名单 
    getCompanyAccount(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/getCompanyAccount',
            params: params,
        })
    },
    //添加黑名单 
    joinBlacklist(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/joinBlacklist',
            data: params,
        })
    },
    //黑名单门店商家列表
    getBlacklist(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/getBlacklist',
            data: params,
        })
    },
    //移出黑名单
    removeBlacklist(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/removeBlacklist',
            params: params,
        })
    },
    //设置手续费
    setServiceFee(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/pay/apply/setServiceFee',
            data: params,
        })
    },
    //门店商下账
    companyBalanceDown(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/detail/companyBalanceDown',
            data: params,
        })
    },
    //差异基金（虚拟）上下账
    fictitiousupdownDown(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/detail/company-diff-fund-fictitious-updown',
            data: params,
        })
    },
    saveMatchLogo(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matchChannel/saveMatchLogo',
            data: params,
        })
    },

    //订单列表
    orderList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/list',
            data: params,
        })
    },
    // 订单列表-所有报价商
    orderStoreList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/listQuoteByOrderNo',
            params,
        })
    },
    // 订单列表-恢复已取消记录
    getCancelRestore(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/getCancelRestore',
            params,
        })
    },
    // 订单动态
    orderFlow(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/orderFlow',
            params,
        })
    },
    //订单详情
    orderInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/info',
            params: params,
        })
    },
    orderExcel(params) {
        return request({
            url: '/platform/pc/order/excel',
            method: 'post',
            data: params,
            responseType: 'blob'
        })
    },
    //合作商家下拉查询列表数据
    SelectCompany(params) {
        return request({
            method: 'GET',
            url: '/merchant/pc/merchantUnionCompany/getMiddleAdoptCompany',
            params: params,
        })
    },

    // 系统管理
    //职员管理
    StaffManageList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/admin/list',
            data: params,
        })
    },

    //职员管理
    StaffInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/admin/info',
            params: params,
        })
    },
    //账号状态
    staffListSwitch(params) {
        return request({
            method: 'put',
            url: '/platform/pc/admin/isEnable',
            data: params,
        })
    },
    //重置密码
    resetPassword(params) {
        return request({
            method: 'get',
            url: '/platform/pc/admin/resetPassword',
            params: params,
        })
    },
    //添加职员
    StaffSave(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/admin/save',
            data: params,
        })
    },
    //导航菜单
    getMenuList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/admin/menu',
            params,
        })
    },
    //获取配置
    ResourcesConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/config/get',
            data: params,
        })
    },
    //获取追差配置
    getAprConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/config/getApr',
            data: params,
        })
    },
    //保存追差配置
    updateAprConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/config/modifyApr',
            data: params,
        })
    },
    //修改配置
    ResourcesEdit(params) {
        return request({
            method: 'POST',
            url: 'platform/pc/config/modify',
            data: params,
        })
    },


    //角色列表
    RoleList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/role/list',
            params: params,
        })
    },
    //删除角色
    RoleDelete(params) {
        return request({
            method: 'DELETE',
            url: '/platform/pc/role/delete',
            params: params,
        })
    },
    //保存角色
    RoleSaveUser(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/role/saveRole',
            data: params,
        })
    },
    //角色详情
    RoleInfoRes(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/role/infoRes',
            params: params,
        })
    },
    //角色权限
    allRes(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/role/allRes',
            params: params,
        })
    },
    //按钮权限
    allButton(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/role/allButton',
            params: params,
        })
    },

    //意见反馈
    //反馈列表
    complaintList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/complaint/complaintList',
            data: params,
        })
    },
    //处理和备注
    replyComplaint(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/complaint/replyComplaint',
            data: params,
        })
    },

    //门店商家列表
    companyList(params) {
        return request({
            method: 'POST',
            url: 'platform/pc/company/list',
            data: params,
        })
    },
    //人工审单订单列表
    estimateorderList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/estimate/order/list',
            data: params,
        })
    },
    //人工审单订单完美点评
    perfectorderList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/estimate/order/perfect',
            data: params,
        })
    },
    //人工审单订单补拍
    reimgorderList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/estimate/order/reimg',
            data: params,
        })
    },
    //人工审单订单驳回
    rejectorderList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/estimate/order/reject',
            data: params,
        })
    },
    //人工审单订单规范提醒
    standardorderList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/estimate/order/standard',
            data: params,
        })
    },
    //获取示例图（补拍）
    standSampleImageList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/estimate/order/getSampleImageList',
            params: params,
        })
    },
    //导出-人工审单
    standExcel(params) {
        return request({
            method: 'post',
            url: '/platform/pc/estimate/order/excel',
            data: params,
            responseType: 'blob'
        })
    },
    //渠道商家列表
    canclecompanyList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/agentStatistics/companyList',
            data: params,
        })
    },
    //关联商家
    unionMerchantList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/unionMerchantList',
            data: params,
        })
    },
    //关联商家
    setCanCallerMerchant(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/setCanCallerMerchant',
            data: params,
        })
    },
    //是否统一使用报价机型配置
    openDefaultQuoteBatch(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/openDefaultQuoteBatch',
            data: params,
        })
    },
    // 报价服务费详情
    getServerFeeDetail(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/getQuoteChannelServerFee',
            data: params,
        })
    },
    // 设置报价服务费
    setChannelServerFee(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/setQuoteChannelServerFee',
            data: params,
        })
    },
    //商家返利下拉
    getMiddleAdoptMerchant(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/getMiddleAdoptMerchant',
            params: params,
        })
    },
    //商家返利
    unionMerchantProfit(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/unionMerchantProfit',
            data: params,
        })
    },
    //查询收货地址
    getReceiveAddress(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/getReceiveAddress',
            params: params,
        })
    },
    //门店列表
    getStoreList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/getStoreList',
            data: params,
        })
    },
    //门店下拉列表
    getStoreSelectList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/getStoreSelectList',
            params: params,
        })
    },

    // 门店商家下拉列表
    getSelectList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/getSelectList',
            params: params,
        })
    },
    //Q-机型下拉列表
    getlistSelected(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/listSelected',
            data: params,
        })
    },
    //独立部署-获取分类图标
    getTypeIconList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/dep/getTypeIcon',
            data: params,
        })
    },
    //独立部署-保存分类图标
    saveTypeIconList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/dep/saveTypeIcon',
            data: params,
        })
    },
    //职员列表
    getStaffList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/getStaffList',
            data: params,
        })
    },
    //获取锁定店员页面记录
    getLockList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/getLockStaffPage',
            data: params,
        })
    },

    //锁定店员页面
    lockStaffPage(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/lockStaffPage',
            data: params,
        })
    },

    // 催货完结
    doOver(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/error/track/doOver',
            params: params,
        })
    },

    //快递下单数据列表
    dptmgetList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/send/getList',
            data: params,
        })
    },
    //快递下单 去发货前查询数据
    beforeGrabShipView(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/send/beforeGrabShipView',
            data: params,
        })
    },
    //快递下单 保存发货默认人
    saveSenderpople(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/send/saveSender',
            data: params,
        })
    },
    //快递下单 旧机列表
    getOldphoneList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/send/getOrderList',
            data: params,
        })
    },
    //去发货前查询数据(订单列表)
    pickParcelChooseOrderList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/send/pickParcelChooseOrderList',
            data: params,
        })
    },
    //快递下单 导出Excel
    expressageExcel(params) {
        return request({
            method: 'post',
            url: '/platform/pc/dptm/send/getExcel',
            data: params,
            responseType: 'blob'
        })
    },
    //Q-导出借款订单
    reportBorrowExcel(params) {
        return request({
            method: 'post',
            url: '/platform/pc/order/borrow/excel',
            data: params,
            responseType: 'blob'
        })
    },
    //快递下单 保存发货地址
    saveSenderAddress(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/send/saveSenderAddress',
            data: params,
        })
    },
    //快递下单 分配包裹(去发货)
    sendpickParcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/send/pickParcel',
            data: params,
        })
    },
    //快递下单 立建下单
    immediatelyBuild(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/send/build',
            data: params,
        })
    },
    //快递下单 管理员下拉列表
    getadminSelectList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/admin/getSelectList',
            params,
        })
    },
    //修改排序
    updatepoterSort(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/renew/poster/updateSort',
            params,
        })
    },
    //是否上架
    updateIsListing(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/renew/poster/updateIsListing',
            params,
        })
    },
    //是否启用换新补贴
    updateOpenRenewSubsidy(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/updateOpenRenewSubsidy',
            params,
        })
    },
    //仓库下拉)
    getwarehouseSelectList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/platformAddress/warehouse/pull/down',
            params,
        })
    },
    //快递下单 退出发货（通过门店id）
    quitGrabShipStore(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/dptm/send/quitGrabShipStore',
            params,
        })
    },
    //快递下单 退出发货（通过批次id）
    quitGrabShipBatch(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/dptm/send/quitGrabShipBatch',
            params,
        })
    },
    //快递下单 批次详情
    getBatchInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/dptm/send/getBatchInfo',
            params,
        })
    },
    //快递下单 快递下单统计
    getorderSendStatistics(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/send/orderSendStatistics',
            data: params,
        })
    },
    //借款金额统计
    findLoanAmountStatistics(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/platformFinance/statistics/findLoanAmountStatistics',
            data: params,
        })
    },
    //启用禁用授信业务
    enableFinanceAccount(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/platformFinance/statistics/enableFinanceAccount',
            data: params,
        })
    },
    //额度配置记录
    findFinanceUpDownLogs(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/platformFinance/statistics/findFinanceUpDownLogs',
            data: params,
        })
    },
    //平台额度
    findPlatformLimit(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/platformFinance/statistics/findPlatformLimit',
            params,
        })
    },
    //平台总额度调整
    updateFinanceUpDown(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/platformFinance/statistics/updateFinanceUpDown',
            data: params,
        })
    },
    //快递下单 发货成功快递统计
    getsendSuccessStatistics(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/send/sendSuccessStatistics',
            data: params,
        })
    },
    //快递下单 待发货门店统计
    getwaitSendStoreStatistics(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/dptm/send/waitSendStoreStatistics',
            params,
        })
    },
    //快递下单 获取上门时间
    getSendStartTime(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/dptm/send/getSendStartTime',
            params,
        })
    },
    //快递下单 获取发货人列表
    getSenderList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/send/getSenderList',
            data: params,
        })
    },
    //生成门店后台账号
    createPcAcc(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/createPcAcc',
            params: params,
        })
    },
    //分配后台账号
    assignStaffAcc(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/assignStaffAcc',
            data: params,
        })
    },
    //分配后台账号
    updateIsOpenPc(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/updateIsOpenPc',
            params: params,
        })
    },
    //回收商 生成PC后台账号
    createRecyclerPcAcc(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/recycle/merchant/createPcAcc',
            params: params,
        })
    },
    //回收商 分配后台账号
    assignRecyclerStaffAcc(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/assignStaffAcc',
            data: params,
        })
    },
    //回收商 分配后台账号
    updateRecyclerIsOpenPc(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/recycle/merchant/updateIsOpenPc',
            params: params,
        })
    },
    //安装小程序导企业应用中去
    installMiniwechat(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/install/miniwechat',
            data: params
        })
    },
    //强制退登录
    forciblyLoginOut(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/forciblyLoginOut',
            params
        })
    },

    //视频演示
    //视频列表
    domeVideoList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/domeVideo/list',
            data: params,
        })
    },
    //详情
    domeVideoInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/domeVideo/info',
            params: params,
        })
    },
    //编辑视频详情提交
    updateDomeVideo(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/domeVideo/updateDomeVideo',
            data: params,
        })
    },
    //添加视频详情提交
    addDomeVideo(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/domeVideo/addDomeVideo',
            data: params,
        })
    },
    //上下架
    updateIsEnable(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/domeVideo/updateIsEnable',
            params: params,
        })
    },
    //是否首页
    updateIsHome(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/domeVideo/updateIsHome',
            params: params,
        })
    },
    //删除
    VedioDelete(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/domeVideo/delete',
            params: params,
        })
    },
    //修改排序
    updateSort(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/domeVideo/updateSort',
            params: params,
        })
    },

    //分类列表
    classList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/oldMachineType/list',
            data: params,
        })
    },

    //门店商家列表
    classCompanyListt(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/oldMachineType/companyList',
            data: params,
        })
    },

    //门店商家-分类列表
    companyMachineType(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/oldMachineType/companyMachineType',
            data: params,
        })
    },

    //门店商家-编辑分类
    saveCompanyMachineType(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/oldMachineType/saveCompanyMachineType',
            data: params,
        })
    },

    // 指定品牌下拉列表
    selectAssignBrandList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldMachineType/selectAssignBrandList',
            params,
        })
    },

    // 添加指定品牌
    assignBrand(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/oldMachineType/assignBrand',
            data: params,
        })
    },

    findInquiryMerchant(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/findInquiryMerchant',
            data: params,
        })
    },
    //是否启用
    classEnable(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldMachineType/updateIsEnable',
            params: params,
        })
    },

    //线上业务是否启用
    updateIsEnableOnline(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldMachineType/updateIsEnableOnline',
            params,
        })
    },

    //统一配置到所有门店商家
    configToAll(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldMachineType/configToAll',
            params: params,
        })
    },
    //添加分类
    classAdd(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/oldMachineType/save',
            data: params,
        })
    },
    //【采货侠】旧机分类下拉列表
    getChxSelectList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldMachineType/getChxSelectList',
            params,
        })
    },
    //【分毫】旧机分类下拉列表
    getFhSelectList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldMachineType/getFhSelectList',
            params,
        })
    },
    //修改排序
    classSort(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldMachineType/updateSort',
            params: params,
        })
    },
    // 详情
    classInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldMachineType/info',
            params: params,
        })
    },
    // 下拉列表
    selectList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldMachineType/selectList',
            params: params,
        })
    },
    // 是否开启苹果锁验证
    openAppleIdValid(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldMachineType/openAppleIdValid',
            params: params,
        })
    },
    // 机型管理
    // 机型列表
    modelList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/list',
            data: params,
        })
    },
    // 机型标记
    modelMark(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/mark',
            data: params,
        })
    },
    //禁用/启用
    modelDisable(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/enDisable',
            data: params,
        })
    },
    //修改排序
    modelSort(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/sort',
            data: params,
        })
    },
    // 删除
    modelDelete(params) {
        return request({
            method: 'DELETE',
            url: '/platform/pc/machine/model/remove',
            params: params,
        })
    },
    // 添加机型
    modelAdd(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/add',
            data: params,
        })
    },
    // 导入机型
    importAdd(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/import',
            data: params,
        })
    },
    // 编辑机型
    modelEdit(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/edit',
            data: params,
        })
    },
    // 修改旧机信息
    modifyPhoneInfo(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/estimate/order/modifyPhoneInfo',
            data: params,
        })
    },
    // 刷新机型品牌
    modelRefresh(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/machine/model/refresh',
            params,
        })
    },
    // Q-报全部机型-指定机型（分页）
    listByTypeBrandPage(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/listByTypeBrandPage',
            data: params,
        })
    },
    // Q-机型（已添加和未添加）
    listAddedUnAdd(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/listAddedUnAdd',
            data: params,
        })
    },
     // Q-机型隐藏ID
     listAddedUnAddHideId(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/listAddedUnAddHideId',
            data: params,
        })
    },
    // 内存运存
    getMachineSpec(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/machine/model/getMachineSpec',
            params,
        })
    },
    getMachineChannelList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/machine/model/getMachineChannelList',
            params,
        })
    },
    //查看预估价
    seaViewpreprice(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/view-pre-price',
            params,
        })
    },
    //发起申请
    actionAprgeneral(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr-general/apply',
            data: params,
        })
    },
      //撤回售后
      actionRevocation(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr-general/revocation',
            data: params,
        })
    },
      //详情
      actionAprgdetail(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr-general/detail',
            data: params,
        })
    },
    // 添加故障
    failureAdd(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/coreFailure/addCoreFault',
            data: params,
        })
    },
    // 获取机型配置
    getCoreFailureMachineConfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/coreFailure/getMachineConfig',
            params,
        })
    },
    // 保存机型配置
    saveCoreFailureMachineConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/coreFailure/saveMachineConfig',
            data: params,
        })
    },
    // 删除故障
    failureDel(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/coreFailure/removeById',
            params: params,
        })
    },
    // 酬金列表操作[操作]
    quoteProfileListOper(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/quoteProfileListOper',
            data: params,
        })
    },
    // 二维码批次列表
    barCodeList(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCode/list',
            data: params,
        })
    },
    // 已分配商家列表
    distributionList(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCode/distributionList',
            data: params,
        })
    },
    // 生成二维码
    generateQRCode(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCode/generateQRCode',
            data: params,
        })
    },
    // 批量导入商家列表
    barCodeImport(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCode/import',
            data: params,
        })
    },
    // 商家列表
    barCodeCompanyList(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCode/companyList',
            data: params,
        })
    },
    // 添加待分配激活商家
    addUnDistributionCompany(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCode/addUnDistributionCompany',
            data: params,
        })
    },
    // 确认分配
    distribution(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCode/distribution',
            data: params,
        })
    },
    // 印刷完成时间
    printDate(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCode/printDate',
            data: params,
        })
    },
    // 二维码批次备注
    barCodeRemark(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCode/remark',
            data: params,
        })
    },
    // 验证条码批次
    barCodeValid(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/barCode/valid`,
            params: params,
        })
    },
    // 二维码管理其他信息
    barCodeOtherInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/barCode/otherInfo',
            params: params,
        })
    },
    // 门店商家二维码列表
    barCodeHistoryList(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCodeHistory/list',
            data: params,
        })
    },
    // 门店商家二维码历史分配
    barCodeHistory(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCodeHistory/history',
            data: params,
        })
    },
    // 二维码查询
    barCodeQuery(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/barCode/barCodeQuery',
            params: params,
        })
    },
    // -其他条码查询
    otherCodeQuery(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/barCode/otherCodeQuery',
            params: params,
        })
    },
    // 二维码批次号列表
    batchCodeSelectList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/barCodeSendRecord/batchCodeSelectList',
            params: params,
        })
    },
    // Q-议价详情
    getExpectedInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/getExpectedInfo',
            params
        })
    },
    // 二维码发货数据列表
    barCodeSendRecordList(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCodeSendRecord/list',
            data: params,
        })
    },
    // 本批次本商户分配记录
    getDistribute(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCodeSendRecord/getDistribute',
            data: params,
        })
    },
    // 通过批次码查询绑定商家
    companyListByBarCode(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/barCodeSendRecord/companyListByBarCode',
            params: params,
        })
    },
    // 添加发货记录
    barCodeSendRecordSave(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCodeSendRecord/save',
            data: params,
        })
    },
    // 二维码发货添加备注
    saveRemark(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCodeSendRecord/saveRemark',
            data: params,
        })
    },
    // 批量添加发货记录
    importSendRecord(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCodeSendRecord/importSendRecord',
            data: params,
        })
    },
    // 添加押金记录
    saveSendDeposit(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCodeSendRecord/saveSendDeposit',
            data: params,
        })
    },
    // 查询押金记录
    getSendDeposit(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCodeSendRecord/getSendDeposit',
            data: params,
        })
    },
    // 导出发货Excel
    exportBarCodeExcel(params) {
        return request({
            method: 'post',
            url: '/platform/pc/barCodeSendRecord/exportExcel',
            data: params,
            responseType: 'blob'
        })
    },
       // 导出Excel
       aprgeneralExcel(params) {
        return request({
            method: 'post',
            url: '/platform/pc/order/apr-general/excel',
            data: params,
            responseType: 'blob'
        })
    },
    // 导出平台借款账单列表
    getFinanceExcel(params) {
        return request({
            method: 'post',
            url: '/platform/pc/account/detail/getFinanceExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // 支付设置
    // 线上申请支付列表
    applyList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/pay/apply/list',
            data: params,
        })
    },
    // 支付修改备注
    applyMasterNote(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/pay/apply/masterNote',
            data: params,
        })
    },
        // 列表
        getAprgenerallist(params) {
            return request({
                method: 'POST',
                url: '/platform/pc/order/apr-general/list',
                data: params,
            })
        },
    // 启用禁用门店商家线上支付
    enableCompanyPay(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/pay/apply/enableCompanyPay',
            data: params,
        })
    },
    // 审核
    auditCompanyPayApply(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/pay/apply/auditCompanyPayApply',
            data: params,
        })
    },
    // 查看关联商家
    getUnionMerchantList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/pay/apply/getUnionMerchantList',
            data: params,
        })
    },
    // 支付配置列表
    payConfigList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/pay/apply/payConfigList',
            data: params,
        })
    },
    // 支付配置列表
    addPayBonusConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/pay/apply/addPayBonusConfig',
            data: params,
        })
    },
    // 删除支付设置
    deletePayBonusConfig(params) {
        return request({
            method: 'DELETE',
            url: '/platform/pc/pay/apply/deletePayBonusConfig',
            params: params,
        })
    },
    // 发送支付配置推送
    sendPayConfigMsg(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/pay/apply/sendPayConfigMsg',
            params: params,
        })
    },
    // 支付配置详情
    configInfo(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/pay/apply/configInfo',
            data: params,
        })
    },
    // 添加在线支付设置
    addOnlinePayConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/pay/apply/addOnlinePayConfig',
            data: params,
        })
    },
    // 编辑在线支付设置
    editOnlinePayConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/pay/apply/editOnlinePayConfig',
            data: params,
        })
    },
    // 解析导入门店
    analysisStoreExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/pay/apply/analysisStoreExcel',
            data: params,
        })
    },
    // 修改靓机成交付款模式设置
    updateNiceAckPayment(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/pay/apply/updateNiceAckPayment',
            params: params,
        })
    },
    // 回收商账务列表
    accountList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/updown/list',
            data: params,
        })
    },
    // 回收商账务列表-Q列表
    getAccountList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/updown/list',
            data: params,
        })
    },
    // 回收商账务设置备注
    accountNote(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/updown/note', // '/platform/pc/recycle/merchant/account/note',  
            data: params,
        })
    },
    // 查看凭证
    getVoucher(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/updown/voucher', // '/platform/pc/recycle/merchant/account/voucher',
            data: params,
        })
    },
    // 更换凭证
    voucherUpdate(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/updown/voucher/update', // '/platform/pc/recycle/merchant/account/voucher/update',
            data: params,
        })
    },
    // 上账
    addAccount(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/account/add',
            data: params,
        })
    },
    // 下账
    subAccount(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/account/sub',
            data: params,
        })
    },
    // 商户账务上账
    addMerchantAccount(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/updown/add',
            data: params,
        })
    },
    // 商户账务下账
    subMerchantAccount(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/updown/sub',
            data: params,
        })
    },
    // 添加微信流水单号
    wechatFollowNo(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/updown/wechatFollowNo', // '/platform/pc/recycle/merchant/account/wechatFollowNo',
            data: params,
        })
    },
    // 账务导出
    accountExportExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/updown/excel', // '/platform/pc/recycle/merchant/account/excel',
            data: params,
            responseType: 'blob'
        })
    },
    // 退款申请列表
    refundList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/refund/list',
            data: params,
        })
    },
    // 退款申请审核
    refundAudit(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/refund/audit',
            data: params,
        })
    },
    // 退款申请添加备注
    refundMasterNote(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/refund/masterNote',
            data: params,
        })
    },
    // 余额提醒列表
    warnList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/merchant/warn/list',
            data: params,
        })
    },
    // 设置余额提醒阈值
    setWarnValue(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/merchant/warn/setWarnValue',
            data: params,
        })
    },
    // 余额提醒添加备注
    warnMasterNote(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/merchant/warn/masterNote',
            data: params,
        })
    },
    // 查看线上合作商家
    onlinePayCompanyList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/merchant/warn/onlinePayCompanyList',
            data: params,
        })
    },
    // 支付账单列表
    getAccountPayList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/detail/getList',
            data: params,
        })
    },
    // 平台借款账单列表
    getFinanceList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/detail/getFinanceList',
            data: params,
        })
    },
    // 回收商总差异基金
    merchantDiffFund(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/account/detail/merchantDiffFund',
            params,
        })
    },
    // 当前总差异基金
    getAllDiffFund(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/account/detail/allDiffFund',
            params,
        })
    },
    // 账单子类型查询
    getSerTypeList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/account/detail/getSerTypeList',
            params,
        })
    },
    // 账单导出
    billExportExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/detail/exportExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // 物流导出
    excelexpressorder(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/express/order/excel',
            data: params,
            responseType: 'blob'
        })
    },

    // 出账列表(付款)
    transferList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/wechat/transfer/transferList',
            data: params,
        })
    },

    // 出账列表(退款)
    transferListRefund(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/wechat/transfer/transferListRefund',
            data: params,
        })
    },
    // 入账列表
    wechatRefundList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/wechat/transfer/refundList',
            data: params,
        })
    },
    // Excel-出账微信账户列表
    transferExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/wechat/transfer/transferExcel',
            data: params,
            responseType: 'blob'
        })
    },

    // Excel-出账导表(退款)
    transferListRefundExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/wechat/transfer/transferListRefundExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // Excel-入账微信账户列表
    refundExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/wechat/transfer/refundExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // 微信账户数量统计
    statisticsNum(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/wechat/transfer/statisticsNum',
            data: params,
        })
    },
    // 出账备注
    transferNote(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/wechat/transfer/note',
            data: params,
        })
    },
    // 入账备注
    noteRefund(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/wechat/transfer/noteRefund',
            data: params,
        })
    },
    // 打款审核
    payAudit(params) {
        return request({
            method: 'POST',
            url: '/payment/api/pay/audit',
            data: params,
        })
    },
    // 出账补单查询
    transferQuery(params) {
        return request({
            method: 'GET',
            url: '/payment/api/pay/transferQuery',
            params: params,
        })
    },

    // 重新退款
    againRefund(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/wechat/transfer/againRefund',
            params: params,
        })
    },
    // 入账补单查询
    refundQuery(params) {
        return request({
            method: 'GET',
            url: '/payment/api/pay/refundQuery',
            params: params,
        })
    },
    // 添加利润设置
    addOfflinePayConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/addOfflinePayConfig',
            data: params,
        })
    },
      // 获取配置
      getlbshconfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/automatic/quote/config/temp/get',
            params: params,
        })
    },
     // 保存配置
     savelbshconfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/automatic/quote/config/temp/save',
            data: params,
        })
    },
    // 编辑利润设置
    editOfflinePayConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/editOfflinePayConfig',
            data: params,
        })
    },
    // 利润设置详情
    companyConfigInfo(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/configInfo',
            data: params,
        })
    },
    // 重新打款
    transferManual(params) {
        return request({
            method: 'GET',
            url: '/payment/api/pay/transferManual',
            params: params,
        })
    },
    // 余额提醒信息
    getSendWarnLog(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/merchant/warn/getSendWarnLog',
            data: params,
        })
    },
    // 查询体验赠送和交易扣费数据
    getReceivingConfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/payment/config/getReceivingConfig',
            params,
        })
    },
    // 编辑体验赠送交易扣费
    submintGive(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/payment/config/updateGoldRulesConfig',
            data: params,
        })
    },
    // 查看体验赠送和交易扣费变更记录
    getReceivingConfigLog(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/payment/config/getReceivingConfigLog',
            data: params,
        })
    },
    // 查看套餐变更记录
    getRechargeConfigLog(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/payment/config/getRechargeConfigLog',
            data: params,
        })
    },
    // 查看套餐列表
    getRechargeConfigList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/payment/config/getRechargeConfig',
            params
        })
    },
    // 新增编辑套餐
    updateRechargeConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/payment/config/updateRechargeConfig',
            data: params
        })
    },
    // 删除套餐
    removeRechargeConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/payment/config/removeRechargeConfig',
            data: params
        })
    },
    // 充值订单列表
    getPcOrderList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recharge/order/getPcOrderList',
            data: params
        })
    },
    // 下账列表
    listByMinus(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/merchant/listByMinus',
            data: params
        })
    },
    // 充值订单导出
    getPcOrderExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recharge/order/getPcOrderExcel',
            data: params,
            responseType: 'blob'
        })
    },
    //下账订单导出
    merchantExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/merchant/excel',
            data: params,
            responseType: 'blob'
        })
    },
    // 充值订单列表
    getPayList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/getMerchantRechargeOrderList',
            data: params
        })
    },
    // 使用记录列表
    getMerchantUsedLog(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/getMerchantUsedLog',
            data: params
        })
    },
    // 串号查询记录
    gecategoryListRecord(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/imei/detection/category/list',
            data: params
        })
    },
    // 购买人（使用人）列表
    recycleStaffSelect(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/recycle/merchant/recycleStaffSelect',
            params
        })
    },
    // 报价师（创建人）下拉列表
    recycleStaffList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/recycle/merchant/recycleStaffList',
            params
        })
    },
    // 管理员下拉列表
    manageStaffSelect(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/manageStaffSelect',
            params
        })
    },
    // 版本更新列表
    getVersionList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/version/log/list',
            data: params
        })
    },
    //证书数量统计
    stateStatistics(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/webcerts/stateStatistics',
            data: params
        })
    },
    // 导出数据证书
    exportWebcertsexcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/webcerts/excel',
            data: params,
            responseType: 'blob'
        })
    },
    //添加证书
    addWebcerts(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/webcerts/add',
            data: params
        })
    },
    //添加合同
    addContract(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/contract/add',
            data: params
        })
    },
    //作废合同
    cancelledContract(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/contract/cancelled',
            data: params
        })
    },
    //更新证书
    updateWebcerts(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/webcerts/update',
            data: params
        })
    },
    //证书停用
    doDisableWebcerts(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/webcerts/doDisable',
            data: params
        })
    },
    //证书延期记录
    delayLogsRecord(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/webcerts/delayLogs',
            data: params
        })
    },
    //证书延期
    doDelayWebcerts(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/webcerts/doDelay',
            data: params
        })
    },
    //证书列表
    WebcertList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/webcerts/list',
            data: params
        })
    },
    //证书下拉列表
    selectListWebcert(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/webcerts/selectList',
            data: params
        })
    },
    //保存版本更新记录
    saveVersionLog(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/version/log/saveVersionLog',
            data: params
        })
    },
    // 版本详情
    getVersionInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/version/log/info',
            params
        })
    },

    //靓机开关
    getNiceMerchant(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/updateIsOpenNiceMerchant',
            params
        })
    },

    // 获取靓机用户信息
    getNiceMerchantInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/getNiceMerchantInfoByCompany',
            params
        })
    },


    // 门店商家列表
    getcompanylist(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/list',
            data: params
        })
    },

    // Q-外部竞价门店列表 -- 管理门店
    findOutQuoteStoreList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/findOutQuoteStoreList',
            data: params
        })
    },

    // 回收商报价门店列表(外部回收商)
    findInquiryOutMerchant(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/findInquiryOutMerchant',
            data: params
        })
    },

    // 外部竞价设置
    saveOutQuoteConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/saveOutQuoteConfig',
            data: params
        })
    },

    // 外部竞价设置详情
    findOutQuoteConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/findOutQuoteConfig',
            data: params
        })
    },

    // 外部竞价设置详情
    openOrClose(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/openOrClose',
            data: params
        })
    },

    // 取消合作（外部回收商）
    cancelCooperation(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/cancelCooperation',
            data: params
        })
    },

    // 回收商报价门店列表(外部回收商)
    unionOutMerchantList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/unionOutMerchantList',
            data: params
        })
    },

    // Q-外部商家报价列表
    getListQuoteByOrderNoOut(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/listQuoteByOrderNoOut',
            params
        })
    },

    // 添加线上支付申请
    applyapply(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/pay/apply/apply',
            data: params
        })
    },

    // 线上支付查询门店列表
    getApplyStoreList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/pay/apply/getStoreList',
            data: params
        })
    },

    // 是否支持撤销收货
    canCancelReceipt(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/pay/apply/canCancelReceipt',
            data: params
        })
    },

    // Excel-导出职员基础信息
    staffExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/staffExcel',
            data: params,
            responseType: 'blob'
        })
    },

    //添加良机
    saveNiceMerchant(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/saveNiceMerchant',
            data: params
        })
    },

    //门店商家启用禁用
    saveenablecompany(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/enableCompany',
            data: params
        })
    },


    setmasterNote(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/masterNote',
            data: params
        })
    },


    //快捷备注排序
    saveupdateSort(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/fault/updateSort',
            params
        })
    },
    //保存预估价配置
    saveEstimatedConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/estimated/config/saveEstimatedConfig',
            data: params
        })
    },
    //获取预估价配置
    getEstimatedConfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/estimated/config/getEstimatedConfig',
            params,
        })
    },
        //SHS预估价列表
        getSHSedConfig(params) {
            return request({
                method: 'GET',
                url: '/platform/pc/pre-price/shs-list',
                params,
            })
        },
        //保存-SHS预估价
    saveSHSConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/pre-price/shs-save',
            data: params
        })
    },
    //获取成交用户显示价价配置
    getAckDownConfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/estimated/config/getAckDownConfig',
            params,
        })
    },
    //保存预估价配置
    saveAckConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/estimated/config/saveAckConfig',
            data: params
        })
    },
    //核心故障
    coreFailurehxin(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/coreFailure/list',
            data: params
        })
    },
    //核心故障是否启用
    updateKernel(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/coreFailure/updateIsEnable',
            params,
        })
    },

    //核心故障排序
    platformupdateSort(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/coreFailure/updateSort',
            params
        })
    },

    //报价修改信息列表
    getUpdatedQuoteLog(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/getUpdatedQuoteLog',
            params
        })


    },
    // 利润排行榜
    rankStatistics(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/rankStatistics',
            data: params
        })
    },
    // 利润排行榜下沉
    rankStatisticsDown(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/rankStatisticsDown',
            data: params
        })
    },
    // 换新补贴-按门店统计
    getRenewStore(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getRenewStore',
            data: params
        })
    },
    // 换新补贴-按员工统计
    getRenewStaff(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getRenewStaff',
            data: params
        })
    },
    // 换新补贴-按换新商品统计
    getRenewProduct(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getRenewProduct',
            data: params
        })
    },
    //渠道 利润排行榜
    canclerankStatistics(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/agentStatistics/rankStatistics',
            data: params
        })
    },

    // 订单成交额

    echartsListOrder(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getStatisticsOrderAmount',
            data: params
        })
    },
    // 订单统计

    storechartsList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getStatisticsOrder',
            data: params
        })
    },


    // 回收商家报价分析
    getMerchantRankInStore(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getMerchantRankInStore',
            data: params
        })
    },
    //回收商家报价振幅
    getMerchantAmplitude(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getMerchantAmplitude',
            data: params,
        })
    },
    //回收商家报价振幅订单
    getMerchantAmplitudeOrder(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getMerchantAmplitudeOrder',
            data: params,
        })
    },
    // 店员数据
    // getOrderDeal(params){
    //     return request({
    //         method:'POST',
    //         url:'/platform/pc/storePc/statistics/getStaffRankInStore',
    //         data:params
    //     })
    // },
    getOrderDeal(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getStaffRankInStore',
            data: params
        })
    },

    // 门店分析
    // /platform/pc/storePc/statistics/orderDeal
    statisticsorderDeal(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/orderDeal',
            data: params
        })
    },
    // 店员询价数据统计
    getStaffInquiryStatistics(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getStaffInquiryStatistics',
            data: params
        })
    },

    // 导出店员询价数据统计
    getStaffInquiryExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getStaffInquiryExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // 各门店交易数据统计
    getStoreStatisticsRank(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getStoreStatisticsRank',
            data: params
        })
    },
    // 导出各门店交易数据统计
    getexcelStoreStatisticsRank(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/excelStoreStatisticsRank',
            data: params,
            responseType: 'blob'
        })
    },
    // 门店新机指标分析
    excelStoreNewMachineAnalysis(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/excelStoreNewMachineAnalysis',
            data: params,
            responseType: 'blob'
        })
    },
    // 各回收商交易数据统计
    getMerchantStatisticsRank(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getMerchantStatisticsRank',
            data: params
        })
    },
    // 导出各回收商交易数据统计
    getexcelMerchantStatisticsRank(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/excelMerchantStatisticsRank',
            data: params,
            responseType: 'blob'
        })
    },
    // Q-非最高报价订单
    qfaOrderList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/qfa/qfaOrderList',
            data: params,
        })
    },
    // Q-回收商交易排行榜【拒绝】报价单列表
    merchantQuoteList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/statistics/merchant-quote-list',
            data: params,
        })
    },
    // Q-Q-机型名称统计
    phoneNameStatistics(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/qfa/phoneNameStatistics',
            data: params,
        })
    },
    //Q-规格统计
    skuStatistics(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/qfa/skuStatistics',
            data: params,
        })
    },
    //Q-标签统计
    biaoqiananalyse(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/qfa/tagStatistics',
            data: params,
        })
    },
    //Q-采集订单明细
    CollectionorderList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/qfa/orderList',
            data: params,
        })
    },
    // Q-非最高报价订单（导表
    qfaOrderListExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/qfa/qfaOrderListExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // Q-非最高报价订单修改成色等级
    updateHealthCodeCus(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/qfa/updateHealthCodeCus',
            data: params,
        })
    },
    //回收商家报价基础数据分析导出
    getMerchantRankInStoreExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getMerchantRankInStoreExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // 撮合交易渠道商列表
    getMatchChannels(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matchChannel/getMatchChannels',
            data: params
        })
    },
    // 撮合交易回收商列表
    getMatchOutChannels(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/recycle/merchant/getMiddleAdoptCompany',
            params
        })
    },
    // 回收商列表（报价时长设置）
    limitExclusiveConfigMerchantList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/limitExclusiveConfigMerchantList',
            data: params
        })
    },
    // 回收商列表（-统计数据配置商列表
    getlargeScaleList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/large-scale-list',
            data: params
        })
    },
    //启用-关闭大范围统计查询功能
    getscaleQueryAction(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/oc-large-scale-query',
            data: params
        })
    },
    // 获取报价时长设置
    getExclusiveConfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/recycle/merchant/getExclusiveConfig',
            params
        })
    },
    // 获取报价时长设置
    saveExclusiveConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/saveExclusiveConfig',
            data: params
        })
    },
    getMoreOutMatch(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/getSelectList',
            params
        })
    },
    // 撮合交易渠道商启用禁用
    updateMatchChannelIsEnable(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/matchChannel/updateMatchChannelIsEnable',
            params
        })
    },
    // 撮合交易是否启用渠道管理后台
    updateMatchChannelOpenBg(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/matchChannel/updateMatchChannelOpenBg',
            params
        })
    },
    // 保存渠道管理后台
    savePcStaff(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matchChannel/savePcStaff',
            data: params
        })
    },
    // 重置密码
    resetStaffPwd(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/matchChannel/resetStaffPwd',
            params
        })
    },
    // 撮合交易渠道商新增
    saveMatchChannel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matchChannel/saveMatchChannel',
            data: params
        })
    },
    // 撮合渠道商下拉列表
    getMatchChannelSelect(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/matchChannel/getMatchChannelSelect',
            params
        })
    },
    // 获取渠道账户信息
    getMatchChannelAccount(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/account/detail/getMatchChannelAccount',
            params
        })
    },
    // 报价金额计算
    quoteOrderPriceCompute(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/quoteOrderPriceCompute',
            params
        })
    },
    // 获取原始预估价检测金额设置
    getRecommendUseDetectionPrice(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/getRecommendUseDetectionPrice',
            params
        })
    },
    // 设置原始预估价检测金额
    setRecommendUseDetectionPrice(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/setRecommendUseDetectionPrice',
            params
        })
    },
    // Q-文案列表
    copyListall(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/copy/list',
            data: params
        })
    },
    // Q-添加文案
    addcopyall(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/copy/add',
            data: params
        })
    },
    // Q-编辑文案
    setcopyall(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/copy/edit',
            data: params
        })
    },
    // Q-上下架文案
    actioncopyall(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/copy/putawayOrDown',
            data: params
        })
    },
    // Q-商户下载排行
    copyCompanyRank(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/copy/companyRank',
            data: params
        })
    },




    // 渠道下账
    matchChannelBalanceDown(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/detail/matchChannelBalanceDown',
            data: params
        })
    },
    // 提交报价
    pushPriceoffer(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/offer',
            data: params
        })
    },
    // 撤销收货
    auditCancelReceipt(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/auditCancelReceipt',
            data: params
        })
    },
    // 报价推送记录
    getPushQuoteRecord(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/getPushQuoteRecord',
            data: params
        })
    },
    // 撮合回收商下拉列表（查询）
    matchMerchantList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matchChannelRelation/merchantList',
            data: params
        })
    },
    // 撮合回收商下拉列表（查询）
    matchMoreMerchantList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/merchantSelectListPlatform',
            data: params
        })
    },
    // 撮合门店商下拉列表（查询）
    matchCompanyList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matchChannelRelation/companyList',
            data: params
        })
    },
    // 撮合商家列表
    matchChannelList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matchChannelRelation/list',
            data: params
        })
    },
    // 创建撮合交易
    addMatchTransaction(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matchChannelRelation/add',
            data: params
        })
    },
    // 创建撮合交易
    addMatchsTransaction(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matchChannelRelation/addBatch',
            data: params
        })
    },
    // 导出撮合交易商家明细
    matchChannelRelationExcel(params) {
        return request({
            method: 'POST',
            url: 'platform/pc/matchChannelRelation/excel',
            data: params,
            responseType: 'blob'
        })
    },
    // 已合作门店商家下拉列表
    getMiddleAdoptCompany(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/recycle/merchant/getMiddleAdoptCompany',
            params
        })
    },
    // 获取订单可修改报价次数
    findOrderQuoteUpdateMax(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/recycle/merchant/findOrderQuoteUpdateMax',
            params
        })
    },
    // 获取修改嗨回收同串号重评配置
    findHiOrderSameImeiQuote(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/recycle/merchant/findHiOrderSameImeiQuote',
            params
        })
    },
    // 修改嗨回收同串号重评配置
    updateHiOrderSameImeiQuote(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/updateHiOrderSameImeiQuote',
            data
        })
    },
    // 修改订单可修改报价次数
    updateOrderQuoteUpdateMax(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/updateOrderQuoteUpdateMax',
            data: params
        })
    },
    // 关闭撮合交易
    closeMatchTransaction(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matchChannelRelation/close',
            data: params
        })
    },
    // 编辑撮合交易
    editMatchTransaction(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matchChannelRelation/edit',
            data: params
        })
    },
    // 撮合交易账单列表
    matchChannelAccountList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matchChannelAccount/list',
            data: params
        })
    },
    // 撮合交易账单导出
    matchChannelAccountExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matchChannelAccount/excel',
            data: params,
            responseType: 'blob'
        })
    },
    // 撮合交易账单列表
    matchOrderDetail(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matchChannelAccount/matchOrderDetail',
            data: params
        })
    },

    // 红包统计列表
    // /platform/pc/redpacket/getCompanyStatistic
    getCompanyStatistic(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/redpacket/getCompanyStatistic',
            data: params
        })
    },
    // 员工红包
    // /platform/pc/redpacket/getStaffInCompanyStatistic
    getStaffInCompanyStatistic(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/redpacket/getStaffInCompanyStatistic',
            data: params
        })
    },

    // 员工红包明细
    // /platform/pc/redpacket/detail
    redpacketdetail(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/redpacket/detail',
            data: params
        })
    },

    // 红包明细订单状态下拉菜单
    // /platform/pc/redpacket/getSerTypeList
    redpacketgetSerTypeList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/redpacket/getSerTypeList',
            params
        })
    },

    // 红包明细 导出表格
    // redpacketdetailexcel
    // /platform/pc/redpacket/detail/excel
    redpacketdetailexcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/redpacket/detail/excel',
            data: params,
            responseType: 'blob'
        })
    },

    // 加价详情
    // /store/pc/order/addPriceInfo
    pcaddPriceInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/addPriceInfo',
            params
        })
    },
    // 内部加价详情
    insideApplyInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/insideApplyInfo',
            params: params
        })
    },

    userbalance(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/admin/get3023Balance',
            params
        })
    },
    //验机报告
    getInspectionReport(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/getInspectionReport',
            params
        })
    },

    // 门店补拍统计列表
    // /store/pc/again/photo/getCensus
    photogetCensus(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/again/photo/getCensus',
            data: params,
        })
    },

    // 门店员工补拍数据统计
    // /store/pc/again/photo/getStoreStaffCensus
    getStoreStaffCensus(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/again/photo/getStoreStaffCensus',
            data: params,
        })
    },

    // 店员待补拍数据
    // /store/pc/again/photo/getStaffWaitOrder
    getStaffWaitOrder(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/again/photo/getStaffWaitOrder',
            data: params,
        })
    },

    // 订单申请补拍记录
    // /store/pc/again/photo/getOrderApplyByOrderNo
    getOrderApplyByOrderNo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/again/photo/getOrderApplyByOrderNo',
            params: params
        })
    },


    // 店员待主动补拍订单
    // /store/pc/again/photo/getStaffInitiativeOrder
    getStaffInitiativeOrder(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/again/photo/getStaffInitiativeOrder',
            data: params,
        })
    },
    // 分类下拉
    activityTypeSelect(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/renew/activity/typeSelect',
            params: params
        })
    },

    // 门店总监
    // /platform/pc/again/photo/inspectorAreaSelect
    inspectorAreaSelect(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/inspectorAreaSelect',
            params: params
        })
    },
    // 菜单管理
    // 平台端菜单列表
    platformMenuList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/menu/platform/list',
            data: params,
        })
    },
    // 平台端保存菜单
    platformSaveMenu(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/menu/platform/save',
            data: params,
        })
    },
    // 平台端修改排序
    platformSortMenu(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/menu/platform/sort',
            params: params,
        })
    },
    // 菜单是否启用
    platformMenuEnable(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/menu/platform/enable',
            params: params
        })
    },
    // 门店端菜单列表
    companyMenuList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/menu/company/list',
            data: params,
        })
    },
    // 回收商家菜单列表
    merchantMenuList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/menu/merchant/list',
            data: params,
        })
    },
    // 运营菜单列表
    marketerMenuList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/menu/marketer/list',
            data: params,
        })
    },
    // 门店端修改排序
    companySortMenu(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/menu/company/sort',
            params: params,
        })
    },
    // 回收端修改排序
    merchantSortMenu(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/menu/merchant/sort',
            params: params,
        })
    },
    // [运营人员]-修改排序
    marketerSortMenu(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/menu/marketer/sort',
            params: params,
        })
    },
    // 门店端保存菜单
    companySaveMenu(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/menu/company/save',
            data: params,
        })
    },
    // 商家端保存菜单
    merchantSaveMenu(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/menu/merchant/save',
            data: params,
        })
    },
    // 运营端保存菜单
    marketerSaveMenu(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/menu/marketer/save',
            data: params,
        })
    },
    // 门店商家菜单
    companyFindMenuTreeMenu(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/menu/company/findMenuTree',
            params: params,
        })
    },
    // 回收商家菜单
    merchantFindMenuTreeMenu(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/menu/merchant/findMenuTree',
            params: params,
        })
    },
    // 菜单是否启用
    companyMenuEnable(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/menu/company/enable',
            params: params
        })
    },
    // 菜单是否启用
    merchantMenuEnable(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/menu/merchant/enable',
            params: params
        })
    },
    // 菜单是否启用
    marketerMenuEnable(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/menu/marketer/enable',
            params: params
        })
    },
    // 统一配置到所有门店商家
    companyConfigToAll(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/menu/company/configToAll',
            params: params
        })
    },
    // 统一配置到所有回收商家
    merchantConfigToAll(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/menu/merchant/configToAll',
            params: params
        })
    },
    // 保存门店商家菜单
    editCompanyMenu(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/menu/editCompanyMenu',
            data: params
        })
    },
    // 保存回收商家菜单
    editMerchantMenu(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/menu/editMerchantMenu',
            data: params
        })
    },
    // 保存门店商家菜单
    companyConfigList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/menu/company/companyList',
            data: params
        })
    },
    // 保存回收商家菜单
    merchantConfigList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/menu/merchant/merchantList',
            data: params
        })
    },


    // 物流申请列表
    logisticsApplyList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/logistics/list',
            data: params
        })
    },
    // 物流申请管理员备注
    logisticsMasterNote(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/logistics/masterNote',
            data: params
        })
    },
    // 获取物流规则
    getLogisticsConfigInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/logistics/getConfigInfo',
            params
        })
    },
    // 待报价是否可跳过订单
    setWaitQuoteJump(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/setWaitQuoteJump',
            data: params
        })
    },
    // 查看合作商家
    getUnionCompanyList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/logistics/getUnionCompanyList',
            data: params
        })
    },
    // 启用-关闭物流
    enableLogistics(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/logistics/enableCompanyPay',
            data: params
        })
    },
    // 【门店】是否开启自动叫快递
    openStoreAutoCallerExpress(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/openStoreAutoCallerExpress',
            data: params
        })
    },
    // 门店商家是否标记为正在使用
    symbolUsedCompany(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/symbolUsedCompany',
            data: params
        })
    },
    // 是否启用预估价模式
    updateUseEstimatedMode(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/updateUseEstimatedMode',
            params
        })
    },
    // 是否开启内部回收
    updateCompanyIsOpenInsideBiz(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/updateCompanyIsOpenInsideBiz',
            params
        })
    },
    // 是否开启内部回收
    updateMiddleIsInside(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/updateMiddleIsInside',
            params
        })
    },
    // 启用-关闭物流
    logisticsAudit(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/logistics/audit',
            data: params
        })
    },
    //平台账单列表
    getaccountPlatform(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/accountPlatform/detail/getList',
            data: params
        })
    },
    //平台账单上账
    PlatformaddBalance(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/accountPlatform/detail/addBalance',
            data: params
        })
    },
    // 平台账单导出
    accountPlatformExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/accountPlatform/detail/exportExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // 平台账单类型查询
    plantSerTypeList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/accountPlatform/detail/getSerTypeList',
            params
        })
    },
    // 添加门店
    companyadd(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/add',
            data: params
        })
    },
    //补贴配置保存
    saveChxExpconfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/makeup/chxExp/config/save',
            data: params
        })
    },
    // 补贴配置信息
    chxExpconfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/makeup/chxExp/config/info',
            params
        })
    },

    companyEdit(params) {
        return request({
            method: "POST",
            url: "/platform/pc/company/update",
            data: params
        })
    },
    //提交补贴加价
    submitMakeUp(params) {
        return request({
            method: "POST",
            url: "/platform/pc/order/submitMakeUp",
            data: params
        })
    },
    // 补贴加价前置信息
    getMakeUpExpInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/getMakeUpExpInfo',
            params
        })
    },

    // 门店详情
    getCompanyById(companyId) {
        return request.post(`/platform/pc/company/detail`, {
            companyId
        })
    },

    // 门店编辑
    updateCompany(params) {
        return request.post("/platform/pc/company/update", params);
    },
    // 添加合作回收商
    saveUnionMerchant(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/saveUnionMerchant',
            data: params
        })
    },
    // 标签下拉列表
    getStoreTagSelect(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/getStoreTagSelect',
            params
        })
    },

    // 门店邀请商家设置
    updateInvite(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/updateInvite',
            data: params
        })
    },
    // 待报价隐藏配置
    waitQuoteHide(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/waitQuoteHide',
            data: params
        })
    },

    // 查询快递列表
    getExpressOrders(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/express/order/getExpressOrders',
            data: params
        })
    },

    // 旧机跟踪日志
    getTraceLog(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/track/log',
            data: params
        })
    },

    // 上报异常
    upErrorTrack(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/error/track/upErrorTrack',
            params
        })
    },

    // 跟进记录
    getFollow(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/error/track/getFollow',
            params
        })
    },

    // 添加催货跟进
    doFollow(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/error/track/doFollow',
            data: params
        })
    },

    // 获取异常催货管理列表
    getErrorTrackList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/error/track/getList',
            data: params
        })
    },

    // 快递详情
    logisticsInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/express/order/logisticsInfo',
            params
        })
    },

    // 分转后快递详情
    trackLogisticsInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/machine/track/logisticsInfo',
            params
        })
    },
    // 取消寄件
    expressOrderCancel(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/express/order/cancel',
            params
        })
    },
    // Q-订单列表【快递-查看旧机】
    getOrderListByExpressNo(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/getOrderListByExpressNo',
            data: params
        })
    },

    // 备注列表
    getNoteList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/track/noteList',
            data: params
        })
    },

    // Q-查询列配置
    findColumns(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/table/column/find',
            params
        })
    },

    // 保存
    configColumns(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/table/column/save',
            data: params
        })
    },

    // 仓库入库旧机
    intoMachine(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/express/order/intoMachine',
            data: params
        })
    },

    // Q-补拍查询
    listRemakeByOrderNo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/listRemakeByOrderNo',
            params
        })
    },
    // Q-补拍查询(采货侠)
    listRemakeByOrderNoCHX(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/listRemakeByOrderNoCHX',
            params
        })
    },
    // Q-撤销收货详情
    findCancelReceiptInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/findCancelReceiptInfo',
            params
        })
    },
    // 回收商家下拉列表
    getMerchantSelectList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/merchantSelectList',
            data: params,
        })
    },
    // 回收商仓储数据排行-入库旧机
    getErpMerchantList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/erp/merchant/getErpMerchantRankModelList',
            data: params,
        })
    },
    // 职员列表（下拉列表）--制单人
    erpStaffSimpleList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/erp/merchant/staffSimpleList',
            data: params,
        })
    },
    // 往来单位下拉框(入库单位)
    erpMerchantPull(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/erp/merchant/into/pull/down',
            params: params,
        })
    },
    // 回收商仓储数据排行-收件包裹
    getErpParcelList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/erp/merchant/getErpMerchantRankParcelList',
            data: params,
        })
    },
    // 入库单据列表
    erpMerchantList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/erp/merchant/list',
            data: params,
        })
    },
    // 导出-入库单据
    erpMerchantExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/erp/merchant/excel',
            data: params,
            responseType: 'blob'
        })
    },
    //获取入驻时间戳
    getEntryTime(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/month/Statistics/getDate',
            params: params,
        })
    },
    //询价量、成交量环比数据
    getInquiryDeal(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/month/Statistics/getInquiryDeal',
            data: params,
        })
    },
    // 环比数据门店人员统计
    getCompanyBasicData(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/storePc/statistics/companyBasicData',
            params,
        })
    },
    //回收交易额、用户成交额&公司毛利环比数据
    getDealFinalProfit(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/month/Statistics/getDealFinalProfit',
            data: params,
        })
    },
    //回收单价&成交客单价&单机毛利
    getAvgPrice(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/month/Statistics/getAvgPrice',
            data: params,
        })
    },
    //公司毛利&员工奖励
    getProfitReward(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/month/Statistics/getProfitReward',
            data: params,
        })
    },
    //毛利率环比数据
    getProfitRate(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/month/Statistics/getProfitRate',
            data: params,
        })
    },
    //成交率、退款、取消率、换新率...
    getMonthRate(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/month/Statistics/getMonthRate',
            data: params,
        })
    },
    //门店商家下拉列表（撮合交易）
    getSelectListMatch(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/getSelectListMatch',
            params: params,
        })
    },
    //门店商家下拉列表（撮合交易-批量选择门店商）
    getSelectListMatchMiddle(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/getSelectListMatchMiddle',
            params: params,
        })
    },
    //已合作回收商下拉列表（只适用撮合交易）
    getMiddleAdoptMerchantMatch(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/getMiddleAdoptMerchantMatch',
            params: params,
        })
    },
    // 是否开启报价时填写机况
    updateIsHealthCodeCus(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/recycle/merchant/updateIsHealthCodeCus',
            params: params,
        })
    },
    // 启用-关闭同步到ERP
    updatesysERP(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/oc-sync-erp',
            data: params,
        })
    },
    // 批量开启显示KA名称
    displayRealname(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matrix/oc-display-real-name',
            data: params,
        })
    },
    // 启用-关闭额度限制
    updateaccountLimit(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/oc-account-limit',
            data: params,
        })
    },
    // 启用-关闭预留差异基金
    updatedifffund(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/oc-diff-fund',
            data: params,
        })
    },
    //启用-关闭外部商户
    isOutOpt(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/isOutOpt',
            data: params,
        })
    },
    //关闭报价
    closeQuote(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/closeQuote',
            data: params,
        })
    },
    //设置报价显示报价服务费
    setQuoteChannelServerFee(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/setQuoteChannelServerFee',
            data: params,
        })
    },
    //重复询价详情
    orderinfoRepeat(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/infoRepeat',
            params,
        })
    },
    //门店商家名称查询
    getrchantSelectList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/getAllSelectList',
            params,
        })
    },
    //查询换新设置
    queryRenewConfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/queryRenewConfig',
            params,
        })
    },
    //订单录音-详情
    getorderSoundinfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/orderRenew/orderSound/info',
            params,
        })
    },
    //保存换新设置
    saveRenewConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/saveRenewConfig',
            data: params,
        })
    },
    //保存平台收货地址
    savePlatformAddress(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/platformAddress/savePlatformAddress',
            data: params,
        })
    },
    //查询平台收货地址
    queryFormAddress(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/platformAddress/query',
            params,
        })
    },
    //查询平台收货地址-弹窗
    queryplatFormAddress(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/queryPlatformAddress',
            data: params,
        })
    },
    //删除平台收货地址
    deplantAddress(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/platformAddress/remove',
            params,
        })
    },
    //门店商家下拉列表
    getCompanySelectList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/dptm/getCompanySelectList',
            params,
        })
    },
    //获取已合作的回收商下拉列表
    getptMerchantList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/dptm/getMiddleAdoptMerchant',
            params,
        })
    },
    //获取门店商平台收货地址
    getCompanyPlatformAddress(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/dptm/getCompanyPlatformAddress',
            params,
        })
    },
    //分转商家统计
    getDptmNumStatistics(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/dptm/getDptmNumStatistics',
            params,
        })
    },
    //获取物流规则
    getConfigInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/dptm/getConfigInfo',
            params,
        })
    },
    //保存海报
    saveposterInfo(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/renew/poster/save',
            data: params,
        })
    },
    //海报列表
    posterListdata(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/renew/poster/list',
            data: params,
        })
    },
    //获取授信商户列表
    getfindFinanceMerchantList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/finance/findFinanceMerchantList',
            data: params,
        })
    },
    //获取授信商户-合作商家列表
    findFinanceCompanyList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/finance/findFinanceCompanyList',
            data: params,
        })
    },
    //回收商合作门店商户启用禁用授信
    cridtenableCompany(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/finance/enableCompany',
            data: params,
        })
    },
    //启用禁用记录
    getfindFinanceEnableLog(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/finance/findFinanceEnableLog',
            data: params,
        })
    },
    //启用禁用
    updateFinanceEnable(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/finance/updateFinanceEnable',
            data: params,
        })
    },
    //获取可添加授信商户列表
    findCanAddFinanceMerchants(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/finance/findCanAddFinanceMerchants',
            data: params,
        })
    },
    //添加授信商户
    addToFinanceMerchant(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/finance/addToFinanceMerchant',
            data: params,
        })
    },
    //授信商户配置
    saveFinanceConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/finance/saveFinanceConfig',
            data: params,
        })
    },
    //商家授信配置记录
    findFinanceConfigLog(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/finance/findFinanceConfigLog',
            data: params,
        })
    },
    // 授信商户配置详情
    getFinanceConfigInfo(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/finance/getFinanceConfigInfo`,
            params: params,
        })
    },
    //保存物流规则
    saveConfigInfo(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/saveConfigInfo',
            data: params,
        })
    },
    //保存分转商家
    saveMiddle(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/dptm/saveMiddle',
            data: params,
        })
    },
    //设置收货方
    updateReceiptType(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/updateReceiptType',
            data: params,
        })
    },
    //获取收货方地址
    queryAddress(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/queryAddress',
            data: params,
        })
    },
    //设置收货方
    setAssign(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/setAssign',
            data: params,
        })
    },
    //出库订单列表
    outStockList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/outStock/list',
            data: params,
        })
    },
    // 入库收货记录列表
    inStockList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/outStock/inStorageList',
            data: params,
        })
    },
    //出库导入记录详情
    outStockImportRecordDetail(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/outStock/importRecordDetail',
            data: params,
        })
    },
    //出库导入记录
    outStockImportRecord(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/outStock/importRecord',
            data: params,
        })
    },
    // 导入已出库订单（仅支持.xls及.xlsx格式）
    outStockImport(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/outStock/import',
            data: params,
        })
    },
    // 导出出库订单
    outStockExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/outStock/excel',
            data: params,
            responseType: 'blob'
        })
    },
    // 导出入库订单
    outStockExcelInStorage(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/outStock/excelInStorage',
            data: params,
            responseType: 'blob'
        })
    },

    // 问题详情
    templateInfo(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/question/template/info`,
            params: params,
        })
    },
    // 问题列表
    templateList(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/question/template/list`,
            data: params,
        })
    },
    // 问题类型下拉
    templateTypeSelect(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/question/template/type/select`,
            data: params,
        })
    },
    // 删除问题
    templateDelete(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/question/template/delete`,
            data: params,
        })
    },
    // 添加问题-更新问题
    templateSaveOrUpdate(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/question/template/saveOrUpdate`,
            data: params,
        })
    },

    // 排序
    templateUpdateSort(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/question/template/update/sort`,
            params: params,
        })
    },
    //是否启用预估价模式（批量）
    updateUseEstimatedModeBatch(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/company/updateUseEstimatedModeBatch`,
            data: params,
        })
    },
    //批量修改绑码方式
    batchUpdateBarCodeBindingType(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/company/batchUpdateBarCodeBindingType`,
            data: params,
        })
    },
    //【门店】设置自动叫快递
    setStoreAutoCallerExpress(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/company/setStoreAutoCallerExpress`,
            data: params,
        })
    },
    //门店商家列表(预估价，自动报价)
    companyCopylisttwo(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/company/list2`,
            data: params,
        })
    },
    //是否开启自动报价
    haveautoQuote(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/matrix/autoQuote`,
            data: params,
        })
    },
      //添加快捷备注必填商户
      addStoreNotRequired(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/company/remark/addStoreNotRequired`,
            data: params,
        })
    },
       //批量删除快捷备注必填商户
       deleteStoreNotRequiredByBatch(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/company/remark/deleteStoreNotRequiredByBatch`,
            data: params,
        })
    },
    //标记厂家订单
    markManufacturers(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/matrix/markManufacturers`,
            data: params,
        })
    },

    // 评论设置
    // 评论列表
    configCommentList(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/comment/config/commentList`,
            data: params,
        })
    },
    // 查询支付id
    getquerypayId(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/order/apr-honor/query-pay-id`,
            data: params,
        })
    },
    //分配前置查询
    beforeCommentDivide(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/comment/config/beforeCommentDivide`,
            params: params,
        })
    },
    //是否启用完善换新机型功能
    updateOpenRenewMachine(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/company/updateOpenRenewMachine`,
            params: params,
        })
    },
    // 分配评论
    configDivide(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/comment/config/divide`,
            data: params,
        })
    },
    // 导入订单
    // 订单列表
    getCommentOrders(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/comment/config/getCommentOrders`,
            data: params,
        })
    },
    // 品牌列表
    oldBrandSelectList(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/oldBrand/selectList`,
            params: params,
        })
    },
    // 品牌列表 下拉列表
    selectByCnd(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/oldBrand/selectByCnd',
            params,
        })
    },
    // 删除不再使用
    setHideOrder(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/comment/config/setHideOrder`,
            params: params,
        })
    },
    removeComment(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/comment/config/removeComment`,
            params: params,
        })
    },
    // 导入订单
    orderImportToComment(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/comment/config/orderImportToComment`,
            data: params,
        })
    },
    configUpdateComment(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/comment/config/updateComment`,
            data: params,
        })
    },

    // 线上回收商家
    shoppingMallList(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/mini/shopping/mall/list`,
            data: params,
        })
    },
    // 修改启动状态
    updateEnable(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/mini/shopping/mall/update/Enable`,
            params: params,
        })
    },
    updateRemark(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/mini/shopping/mall/update/remark`,
            data: params,
        })
    },
    mallAddOrUpdate(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/mini/shopping/mall/addOrUpdate`,
            data: params,
        })
    },
    // 详情
    mallInfo(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/mini/shopping/mall/info`,
            params: params,
        })
    },
    // 门店优势详情
    advantageQuery(params) {
        return request({
            method: 'GET',
            url: `/platform/pc/store/advantage/query`,
            params: params,
        })
    },
    // 保存门店优势
    advantageSave(params) {
        return request({
            method: 'POST',
            url: `/platform/pc/store/advantage/save`,
            data: params,
        })
    },
    //获取自动报价配置
    getQuoteConfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/automatic/quote/config/get',
            params,
        })
    },
    //获取自动报价配置
    getNotQuoteConfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/automatic/otherQuote/config/get',
            params,
        })
    },
    //嗨获取自动报价配置
    gethaiQuoteConfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/automatic/hi/config/get',
            params,
        })
    },
    // 保存自动报价配置
    saveQuoteConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/automatic/quote/config/save',
            data: params,
        })
    },
    // 对外打款列表
    outtranfeList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/out/transfer/list',
            data: params,
        })
    },
    // 对外打款出账备注
    outtranfeNote(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/out/transfer/note',
            data: params,
        })
    },
    // 【外部打款】打款
    outcreateTransfe(params) {
        return request({
            method: 'POST',
            url: '/payment/api/pay/out/createTransfer',
            data: params,
        })
    },

    //对外打款银行下拉列表
    getBankSelect(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/out/transfer/getBankSelect',
            params,
        })
    },
    //【外部打款】补单查询
    outTransferQuery(params) {
        return request({
            method: 'GET',
            url: '/payment/api/pay/outTransferQuery',
            params,
        })
    },
    // 导入订单退单start
    // 退款批次详情列表
    batchDetailList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/platform/refund/order/batchDetailList',
            data: params,
        })
    },
    // 退款批次列表
    batchList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/platform/refund/order/batchList',
            data: params,
        })
    },
    // 读取excel下单包裹（仅支持.xls及.xlsx格式）
    readOutOrderNo(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/platform/refund/order/readOutOrderNo',
            data: params,
        })
    },
    // 导入-批量差异基金（虚拟）上下账
    fictitiousOrderNo(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/account/detail/excel-company-diff-fund-fictitious-updown',
            data: params,
        })
    },
    // 导入指定机型（仅支持.xls及.xlsx格式）
    importAssignMachine(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/importAssignMachine',
            data: params,
        })
    },
    // 取消机型绑定
    cancelBind(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/machine/model/out/cancelBind',
            params,
        })
    },
    // 查询机型详情（平台）
    getPlatformMachine(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/machine/model/out/getPlatformMachine',
            params,
        })
    },
    // 绑定机型列表
    machineModelOutList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/out/list',
            data: params,
        })
    },
    // 绑定机型列表
    platformMachineSelectList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/out/platformMachineSelectList',
            data: params,
        })
    },
    // 查询机型下拉列表（外部）
    outMachineSelectList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/out/outMachineSelectList',
            data: params,
        })
    },
    // 查询机型详情（外部）
    getOutMachine(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/machine/model/out/getOutMachine',
            params,
        })
    },
    // 查询机型详情（外部）
    getOutMachineBrandSelectList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/machine/model/out/getOutMachineBrandSelectList',
            params,
        })
    },
    // 查询机型详情（外部）
    doBindInPlatform(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/out/doBindInPlatform',
            data: params,
        })
    },
    // 查询机型详情（外部）
    doSaveInPlatform(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/out/doSaveInPlatform',
            data: params,
        })
    },
    // 读取excel批量绑定（仅支持.xls及.xlsx格式）
    bindMachineFhBatch(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/machine/model/out/bindMachineFhBatch',
            data: params,
        })
    },
    // 导出繁星本地机型数据
    excelUnBindFh(data) {
        return request.post('/platform/pc/machine/model/out/excelUnBindFh', data, {
            responseType: 'blob'
        })
    },
    // 导出-绑定机型列表
    machineModelOutExcel(data) {
        return request.post('/platform/pc/machine/model/out/excel', data, {
            responseType: 'blob'
        })
    },
    // 批量退款
    saveBatch(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/platform/refund/order/saveBatch',
            data: params,
        })
    },
    // 导入订单退单end

    //获取加价配置
    getMakeupConfig(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/makeup/quote/config/get',
            params,
        })
    },
    //获取谷歌验证二维码
    getAdminGoogleCode(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/admin/getAdminGoogleCode',
            params,
        })
    },
    // 报价限制配置列表
    limitationList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/limitation/list',
            data: params,
        })
    },
    // 配置列表（低价）
    limitationLowList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/limitation/listLow',
            data: params,
        })
    },
    // 报价限制配置保存
    savelimitationconfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/limitation/save',
            data: params,
        })
    },
    // 保存配置（低价）
    savelimitationLowconfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/limitation/saveLow',
            data: params,
        })
    },





    // 保存加价配置
    saveMakeupConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/makeup/quote/config/save',
            data: params,
        })
    },
    //平台加价详情
    getMakeUpInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/getMakeUpInfo',
            params,
        })
    },
    //修改旧机信息记录
    updatePhoneList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/estimate/order/updatePhoneList',
            params,
        })
    },
    //文案修改排序
    copyupdateSort(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/copy/updateSort',
            params,
        })
    },
    //Q-平台通用验证码
    generalCode(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/generalCode',
            params,
        })
    },
    // 标记平台加价
    platformMakeUp(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/platformMakeUp',
            data: params,
        })
    },
    // 撤销平台加价
    cancelMakeUp(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/cancelMakeUp',
            data: params,
        })
    },
    // 订单配置
    orderConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/estimate/config/orderConfig',
            data: params,
        })
    },
    // 商户配置列表
    havecompanyConfig(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/estimate/config/companyConfig',
            data: params,
        })
    },
    // 按订单配置
    saveOrder(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/estimate/config/saveOrder',
            data: params,
        })
    },
    // 按商户配置
    saveCompany(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/estimate/config/saveCompany',
            data: params,
        })
    },
    // 常用打款账户
    Regulardebitaccount(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/out/transfer/contacts',
            data: params
        })
    },
    // 制单
    foreignbuild(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/out/transfer/build',
            data: params
        })
    },
    // 【外部打款】审核
    outditTransfer(params) {
        return request({
            method: 'POST',
            url: '/payment/api/pay/out/auditTransfer',
            data: params
        })
    },
    // 保存公众号配置
    savePublicnumberconfiguration(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/wechat/config/save',
            data: params,
        })
    },
    // 公众号列表
    PublicnumberconfigList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/wechat/config/list',
            data: params,
        })
    },
    //Q-未合作门店商家【获取】
    listCompanyUnMiddle(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/listCompanyUnMiddle',
            data: params,
        })
    },
    //回收商批量添加合作
    allsaveMerchantUnionBatch(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/saveMerchantUnionBatch',
            data: params,
        })
    },
    //回收商批量添加合作
    merchantDefaultQuoteBatch(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/openDefaultQuoteBatch',
            data: params,
        })
    },
    //启用-关闭虚拟报价
    merchanmockQuoteBatch(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/oc-mock-quote',
            data: params,
        })
    },
    //公众号立即重置
    resetPublicma(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/wechat/config/reset',
            data: params,
        })
    },
    //公众号重置记录
    resetListPublicma(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/wechat/config/resetList',
            data: params,
        })
    },
    //公众号添加备注
    resetListaddNote(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/wechat/config/addNote',
            data: params,
        })
    },
    //关闭报价查询最高报价数量
    closeQuoteQueryMaxQuoteNum(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/closeQuoteQueryMaxQuoteNum',
            data: params,
        })
    },
    // 获取加价配置
    makeupConfigGet() {
        return request.get("/platform/pc/makeup/quote/config/get")
    },
    // 未添加门店列表
    makeupFindStore(params) {
        return request.post("/platform/pc/makeup/quote/config/findStore", params)
    },
    // 标签下拉
    getStoreTagSelects() {
        return request.get("/platform/pc/makeup/quote/config/getStoreTagSelect")
    },
    // 总监下拉
    getInspectorAreaSelect() {
        return request.get("/platform/pc/makeup/quote/config/inspectorAreaSelect")
    },
    // 保存设置
    makeupConfigSave(params) {
        return request.post("/platform/pc/makeup/quote/config/save", params)
    },
    // 物流订单对应的回收订单
    recycleOrder(params) {
        return request.post("/platform/pc/express/order/recycleOrder", params)
    },
    // 物流订单强制取消
    cancelForceOrder(params) {
        return request.post("/platform/pc/express/order/cancelForce", params)
    },
    // 查询真实物流状态
    findRealOrderDetail(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/express/order/findRealOrderDetail',
            params,
        })
    },
    // 更新物流状态
    updateRealOrderState(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/express/order/updateRealOrderState',
            data: params,
        })
    },
    // 分转收货列表
    dptmList(params) {
        return request.post("/platform/pc/machine/track/list", params);
    },
    // 线下发货
    dptmBindExpress(params) {
        return request.post('/platform/pc/machine/track/offlineSendExpress', params)
    },
    // 取消线下发货
    cancelOfflineSend(params) {
        return request.post('/platform/pc/machine/track/cancelOfflineSend', params)
    },
    // 添加备注
    dptmNote(params) {
        return request.post("/platform/pc/machine/track/note", params)
    },
    // 导出分货表
    dptmExcel(params) {
        return request.post("/platform/pc/machine/track/excel", params, {
            responseType: 'blob'
        })
    },
    // 取消发货
    cancelSend(params) {
        return request.post("/platform/pc/machine/track/cancelSend", params);
    },
    // 导出分货表
    dptmErrorExcel(params) {
        return request.post("/platform/pc/error/track/excel", params, {
            responseType: 'blob'
        })
    },
    // 导出全部分货表
    // allDptmExcel(params) {
    //     return request.post("/platform/pc/dptm/excelAll", params, {
    //         responseType: "blob"
    //     })
    // },
    // 线下发货详情
    offlineExpressInfo(orderNo) {
        return request.get(`/platform/pc/dptm/offlineExpressInfo?orderNo=${orderNo}`);
    },
    // 强制取消详情
    getCancelForceInfo(expressNo) {
        return request.get(`/platform/pc/express/order/getCancelForceInfo?expressNo=${expressNo}`)
    },
    // 旧机模板列表
    productTemplateList(params) {
        return request.post("/platform/pc/product/template/list", params)
    },
    // 旧机模板是否启用
    productTemplateSetEnable(params) {
        return request.post("/platform/pc/product/template/setEnable", params)
    },
    // 模板详情
    productTemplateDetail(templateCode) {
        return request.get(`/platform/pc/product/template/detail?templateCode=${templateCode}`);
    },
    // 保存模板
    saveProductTemplate(params) {
        return request.post('/platform/pc/product/template/save', params);
    },
    // 矩阵下拉
    getSelectMatrixList(params) {
        return request.get("/platform/pc/matrix/getSelectList")
    },

    // 矩阵列表
    matrixList(data) {
        return request.post("/platform/pc/matrix/list", data)
    },
    // 外部商户
    getOutChannelSelectList() {
        return request.get("/platform/pc/matrix/getOutChannelSelectList")
    },
    // 创建矩阵
    matrixAdd(data) {
        return request.post("/platform/pc/matrix/add", data)
    },
    // 矩阵设置
    matrixPrice(data) {
        return request.post("/platform/pc/matrix/setPriceTime", data)
    },
    // 添加合作回收商
    matrixSaveUnionMerchant(data) {
        return request.post("/platform/pc/matrix/saveUnionMerchant", data)
    },
    // 批量添加合作回收商
    matrixMoreSaveUnionMerchant(data) {
        return request.post("/platform/pc/matrix/saveUnionMerchantBatch", data)
    },
    // 矩阵门店商配置列表
    storeInquiryMerchantList(data) {
        return request.post("/platform/pc/matrix/storeInquiryMerchantList", data)
    },
    // 门店下拉
    getMatrixCompanySelectList(isFilterAdd) {
        return request.get(`/platform/pc/matrix/getCompanySelectList?isFilterAdd=${isFilterAdd}`);
    },
    // 回收下拉
    getMatrixMerchantSelectList(isFilterAdd) {
        return request.get(`/platform/pc/matrix/getMerchantSelectList?isFilterAdd=${isFilterAdd}`);
    },

    // 报价门店列表配置
    doStoreInquiryMerchant(data) {
        return request.post("/platform/pc/matrix/doStoreInquiryMerchant", data);
    },
    // 矩阵报价是否推送
    matrixAgainPushHigh(companyId, isPush) {
        return request.get(`/platform/pc/matrix/matrixAgainPushHigh?companyId=${companyId}&isPush=${isPush}`)
    },
    // 门店商新增矩阵
    saveUnionCompany(data) {
        return request.post('/platform/pc/matrix/saveUnionCompany', data)
    },
    // 关联门店商户
    setCompanyIsMatrix(command, companyId) {
        return request.get(`/platform/pc/matrix/setCompanyIsMatrix?command=${command}&companyId=${companyId}&`)
    },
    setMerchantIsMatrix(command, merchantId) {
        return request.get(`/platform/pc/matrix/setMerchantIsMatrix?command=${command}&merchantId=${merchantId}&`)
    },
    // 矩阵商户当前订单数
    getCompanyInMatrixNoAckOrderNum(companyId) {
        return request.get(`/platform/pc/matrix/getCompanyInMatrixNoAckOrderNum?companyId=${companyId}`)
    },
    // 分转商家快递呼叫
    openPlatformCallExpress(data) {
        return request.post("/platform/pc/dptm/openPlatformCallExpress", data)
    },
    // 订单确定成交
    ackAndBindBode(data) {
        return request.post("/platform/pc/order/ackAndBindBode", data);
    },
    // 商家id查分转详情
    getMiddleByCompanyAndMerchant(companyId, merchantId) {
        return request.get(`/platform/pc/dptm/getMiddleByCompanyAndMerchant?companyId=${companyId}&merchantId=${merchantId}`);
    },
    //查询回收商知否使用中的矩阵里  
    getRecyclerMatrixInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/findMerchantInUseMatrix',
            params,
        })
    },
    // 订单列表成交价明细
    getQuoteCompute(orderNo) {
        return request.get(`/platform/pc/order/getQuoteCompute?orderNo=${orderNo}`)
    },
    // 新核心故障检测提示
    saveDetectionPrompt(data) {
        return request.post("/platform/pc/coreFailure/saveDetectionPrompt", data)
    },
    // 获取核心故障子项
    getCoreFaultOption(id) {
        return request.get(`/platform/pc/coreFailure/getCoreFaultOption?id=${id}`);
    },
    // 保存核心故障
    saveCoreFaultOption(data) {
        return request.post("/platform/pc/coreFailure/saveCoreFaultOption", data)
    },
    // 设置快捷备注
    faultSetConfig(data) {
        return request.post("/platform/pc/fault/setConfig", data);
    },
    // 估价单列表
    chxassessList(data) {
        return request.post("/platform/pc/channelassess/list", data);
    },
    // 机况分类
    getHealthList() {
        return request.get("/platform/pc/channelassess/getHealthList")
    },
    // 估价单导表
    chxassessExcel(data) {
        return request.post("/platform/pc/channelassess/excel", data, {
            responseType: 'blob'
        })
    },
    // 估价单验机报告
    getReport(channelAssessNo) {
        return request.get(`/platform/pc/channelassess/getReport?channelAssessNo=${channelAssessNo}`);
    },
    // 回收门店商导表
    excelStoreInquiryMerchantList(data) {
        return request.post(`/platform/pc/matrix/excelStoreInquiryMerchantList`, data, {
            responseType: "blob"
        })
    },
    // 未开启物流回收商列表
    noOpenMerchantList(data) {
        return request.post("/platform/pc/logistics/noOpenMerchantList", data)
    },

    // 申请开通物流
    logisticsApply(merchantId) {
        return request.post("/platform/pc/logistics/apply", {
            merchantId
        });
    },

    // 保存物流规则
    logisticsSaveConfigInfo(data) {
        return request.post("/platform/pc/logistics/saveConfigInfo", data);
    },
    // 添加物流合作商家
    addLogisticsCompany(data) {
        return request.post("/platform/pc/logistics/addLogisticsCompany", data);
    },
    /// 移除物流合作商
    removeAddressCompany(data) {
        return request.post("/platform/pc/logistics/removeAddressCompany", data);
    },
    // 核账
    approved(data) {
        // return request.post("/platform/pc/recycle/merchant/account/approved", data);
        return request.post("/platform/pc/account/updown/approved", data);
    },
    // 充值次数
    rechargeFrequency(data) {
        return request.post('/platform/pc/merchant/rechargeFrequency', data)
    },
    // 保存追差分类
    catalogSave(data) {
        return request.post("/platform/pc/apr/catalog/save", data)
    },
    // 追差分类列表
    catalogList(data) {
        return request.post("/platform/pc/apr/catalog/list", data)
    },
    //追差修改排序
    catalogUpdateSort(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/apr/catalog/updateSort',
            params,
        })
    },
    //追差分类详情
    catalogInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/apr/catalog/info',
            params,
        })
    },
    // 差异基金详情
    diffFundConfig(data) {
        return request.post("/platform/pc/pay/apply/diffFundConfig", data)
    },
    // 保存差异基金设置
    saveDiffFundConfig(data) {
        return request.post("/platform/pc/pay/apply/saveDiffFundConfig", data)
    },
    // 差异列表
    aprList(data) {
        return request.post('/platform/pc/order/apr/list', data);
    },
    // 权限数组
    adminButton() {
        return request.get("/platform/pc/admin/button");
    },
    // 差异详情
    aprDetatil(data) {
        return request.post("/platform/pc/order/apr/detail", data);
    },
    // 初审
    aprAuditFirst(data) {
        return request.post("/platform/pc/order/apr/auditFirst", data);
    },
    // 暂存
    temporary(data) {
        return request.post("/platform/pc/order/apr/temporary", data);
    },
    // 初审矩阵
    auditFirstOut(data) {
        return request.post('/platform/pc/order/apr/auditFirstOut', data);
    },
    // 驳回
    aprRejecy(data) {
        return request.post('/platform/pc/order/apr/reject', data);
    },
    aprtRejectOut(data) {
        return request.post('/platform/pc/order/apr/rejectOut', data)
    },
    // 复核打款
    auditRe(data) {
        return request.post('/platform/pc/order/apr/auditRe', data);
    },
    // 复核打款矩阵
    auditReOut(data) {
        return request.post('/platform/pc/order/apr/auditReOut', data)
    },
    // 差异审核超5天
    sta(data) {
        return request.post('/platform/pc/order/apr/store/sta', data)
    },
    // 门店审核超5天
    storeSta(data) {
        return request.post('/platform/pc/order/apr/store/sta', data)
    },
    // 门店审核
    storeList(data) {
        return request.post('/platform/pc/order/apr/store/list', data)
    },
    // 门店导表
    storeExcel(data) {
        return request.post('/platform/pc/order/apr/store/excel', data, {
            responseType: 'blob'
        })
    },
    // 平台导表
    aprExcel(data) {
        return request.post('/platform/pc/order/apr/excel', data, {
            responseType: 'blob'
        })
    },
    // 门店差异详情
    aptStoreDetail(data) {
        return request.post(`/platform/pc/order/apr/store/detail`, data)
    },
    // 设置渠道服务费
    setMatchServerFeeOnline(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/recycle/merchant/setMatchServerFeeOnline',
            params,
        })
    },
    // 订单申请补拍记录
    getRenewActivityList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/renew/activity/list',
            data: params
        })
    },
    // Q-借款订单列表
    getborrowList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/borrow/list',
            data: params
        })
    },
    // 手动还款
    financeRepayment(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/borrow/finance-repayment',
            params
        })
    },
    // 手动还款记录
    financeRepaymentrecorder(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/borrow/finance-repayment-log',
            params
        })
    },
    // 报低价提醒详情
    lowPriceAlertInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/low-price-alert-info',
            params
        })
    },
    // 合同列表
    getcontractList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/contract/list',
            data: params
        })
    },
    // 商户换新活动
    // Q-已添加商品列表
    getRenewProductsList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/renew/activity/products',
            data: params
        })
    },
    // Q-已添加门店列表
    getRenewStoresList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/renew/activity/stores',
            data: params
        })
    },
    // 换新订单列表
    orderRenewList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/orderRenew/list',
            data: params
        })
    },
    // 换新订单详情
    findProducts(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/orderRenew/findProducts',
            params,
        })
    },
    // 导出换新订单
    excelOrderRenew(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/orderRenew/excel',
            data: params,
            responseType: 'blob'
        })
    },
    // 导出换新订单
    excelOrderRenewproducts(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/orderRenew/excel-products',
            data: params,
            responseType: 'blob'
        })
    },
    // 换新商品下拉
    productSelect(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/orderRenew/productSelect',
            params,
        })
    },
    // 换新活动下拉
    activitySelect(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/renew/activity/activitySelect',
            params,
        })
    },
    // Q-获取报告配置
    findConfig(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/examine/order/findConfig',
            data,
        })
    },
    // 修改报告配置
    modifyConfig(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/examine/order/modifyConfig',
            data,
        })
    },
    // Q-报价审单列表
    examineOrderList(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/examine/order/list',
            data,
        })
    },
    // Q-报价审单详情
    examineOrderInfo(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/examine/order/info',
            data,
        })
    },
    // 修改验机报告
    examineOrderModify(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/examine/order/modify',
            data,
        })
    },
    // 字典列表
    dict(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/dict/list',
            params,
        })
    },
    // 获取平台操作日志
    getPlatformPcLogList(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/operatorLog/platformPc/getLogList',
            data,
        })
    },
    // 获取平台节点下拉列表
    getPlatformPcNodeNameList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/operatorLog/platformPc/getNodeNameList',
            params,
        })
    },
    // 获取门店操作日志
    getStorePCLogList(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/operatorLog/storePC/getLogList',
            data,
        })
    },
    // 获取门店节点下拉列表
    getStorePCNodeNameList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/operatorLog/storePC/getNodeNameList',
            params,
        })
    },
    // 获取导表操作日志
    getExportExcelList(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/operatorLog/getExportExcelList',
            data,
        })
    },
    // 获取导表节点下拉列表
    getExportExcelNodeNameList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/operatorLog/getExportExcelNodeNameList',
            params,
        })
    },
    // 【导表】获取导表日志-所属终端下拉列表
    getExportExcelReportChannelList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/operatorLog/getExportExcelReportChannelList',
            params,
        })
    },
    // 【导表】获取导表日志-商户下拉列表
    operatorBusinessList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/operatorLog/storePC/operatorBusinessList',
            params,
        })
    },
    // 【导表】获取导表日志-操作人下拉列表
    operatorList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/operatorLog/storePC/operatorList',
            params,
        })
    },
    // 发起申请
    aprHonorApply(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr-honor/apply',
            data,
        })
    },
    // Q-详情
    aprHonorDetail(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr-honor/detail',
            data,
        })
    },
    // Q-列表
    aprHonorList(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr-honor/list',
            data,
        })
    },
    // 修改信息
    aprHonorModify(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr-honor/modify',
            data,
        })
    },
    // 修改信息
    aprHonorModifyList(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr-honor/list-modify',
            data,
        })
    },
    // 修改信息
    aprHonorRevocation(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr-honor/revocation',
            data,
        })
    },
    // 保存回寄快递单号
    aprHonorSaveExpressNo(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr-honor/save-express-no',
            data,
        })
    },
    // 保存回寄快递单号
    aprHonorExcel(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr-honor/excel',
            data,
            responseType: 'blob'
        })
    },
    // [角色]-角色列表
    marketerRoleList(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/marketer/role-list',
            data,
        })
    },
    // [角色]-保存角色
    marketerRoleSave(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/marketer/role-save',
            data,
        })
    },
    // [角色]-角色下拉列表
    marketerRoleSelectList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/marketer/role-select-list',
            params,
        })
    },
    // [角色]-角色详情
    marketerRoleInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/marketer/role-info',
            params,
        })
    },
    // [角色]-删除角色
    marketerRoleDelete(params) {
        return request({
            method: 'DELETE',
            url: '/platform/pc/marketer/role-delete',
            params,
        })
    },
    // 所有栏目
    marketerRoleAllRes(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/marketer/role-all-res',
            params,
        })
    },
    // [运营人员]-查询职员列表
    marketerUserList(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/marketer/user-list',
            data,
        })
    },
    // [运营人员]-运营人员下拉列表
    marketerUserSelectList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/marketer/user-select-list',
            params,
        })
    },
    // [运营人员]-保存职员
    marketerUserSave(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/marketer/user-save',
            data,
        })
    },
    // [运营人员]-启用-禁用
    marketerUserOc(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/marketer/user-oc',
            data,
        })
    },
    // [运营人员]-启用-禁用
    marketerUserResetPassword(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/marketer/user-reset-password',
            data,
        })
    },
    // [运营人员]-获取谷歌验证二维码
    marketerUserGoogleCode(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/marketer/user-google-code',
            params,
        })
    },
    // [运营人员]-门店商家列表
    marketerUserCompanyList(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/marketer/user-company-list',
            data,
        })
    },
    // [运营人员]-添加移除门店商
    marketerUserCompanySave(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/marketer/user-company-save',
            data,
        })
    },
    // 配置信息
    mockQuoteInfo(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/mock-quote/info',
            data,
        })
    },
    // 保存配置
    mockQuoteSave(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/mock-quote/save',
            data,
        })
    },
    // 启用-关闭从账户管理
    ocAccountSlave(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/oc-account-slave',
            data,
        })
    },
    // Q-添加从账户列表
    slaveAddList(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/slave-add-list',
            data,
        })
    },
    // Q-已添加从账户列表
    slaveAddedList(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/slave-added-list',
            data,
        })
    },
    // 添加从账户
    addSlaveAccount(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/add-slave-account',
            data,
        })
    },
    // 解除从账户
    unbindSlaveAccount(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/unbind-slave-account',
            data,
        })
    },
    // Q-从账户商户下拉列表
    getSalveMerchantSelectList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/recycle/merchant/get-salve-merchant-select-list',
            params,
        })
    },
    // 主账户信息
    masterInfo(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/recycle/merchant/master-info',
            params,
        })
    },
    // 获取议价配置
    expConfigInfo(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/exp/config/info',
            data,
        })
    },
    // 保存议价配置
    saveExpConfig(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/exp/config/save',
            data,
        })
    },
    // 通用门店商家列表(不分页)
    getGeneralList(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/general-list',
            data,
        })
    },
    //回收渠道商-审单报价设置
    // 审单商家列表
    examineQuoteList(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/merchant/examine/quote/list',
            data,
        })
    },
    // 添加回收商
    examineQuoteSaveBatch(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/merchant/examine/quote/saveBatch',
            data,
        })
    },
    // 移除回收商
    examineQuoteRemove(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/merchant/examine/quote/remove',
            data,
        })
    },
    // 是否开启水印
    updateIsOpenWatermark(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/merchant/examine/quote/updateIsOpenWatermark',
            params,
        })
    },
    // 是否开启水印
    updateIsExamineUnStart(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/merchant/examine/quote/updateIsExamineUnStart',
            params,
        })
    },
    // 生成谷歌动态口令
    createGoogleCode(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/create-google-code',
            params,
        })
    },
    // 查看谷歌动态口令
    queryGoogleCode(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/query-google-code',
            params,
        })
    },
    // 启用-关闭使用谷歌动态口令
    openCompanyGoogleCode(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/oc-use-google-code',
            data,
        })
    },
    // 启用-关闭是否始终推送
    openMerchantAlwaysPush(data) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/oc-always-push',
            data,
        })
    },
    expExcel(params) {
        return request({
            url: '/platform/pc/order/exp/excel',
            method: 'post',
            data: params,
            responseType: 'blob'
        })
    },
    // 换购录音统计-按门店统计
    getRenewSoundStore(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getRenewSoundStore',
            data: params,
        })
    },
    //导出换购录音统计数据(按门店统计)
    getRenewSoundStoreExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getRenewSoundStoreExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // 换购录音统计-按店员统计
    getRenewSoundStaff(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getRenewSoundStaff',
            data: params,
        })
    },
    //导出换购录音统计数据(按店员统计)
    getRenewSoundStaffExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getRenewSoundStaffExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // 议价订单列表
    getExpOrderList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/exp/list',
            data: params,
        })
    },
    //导出换新补贴数据(按门店统计)
    getRenewStoreExcel(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getRenewStoreExcel',
            data: params,
            responseType: 'blob'
        })
    },
    //换新补贴数据-按员工统计
    getRenewStaffExCEL(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/storePc/statistics/getRenewStaffExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // 导入记录
    aprImportLogList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr/importLogList',
            data: params,
        })
    },
    // 上账记录列表
    getImportLogUpList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr/getImportLogUpList',
            data: params,
        })
    },
    // 导入记录-查看订单
    getImportLogUpOrderList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/apr/getImportLogUpOrderList',
            params,
        })
    },
    // 导入记录-上账记录
    getImportLogUpInfoByBatch(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/apr/getImportLogUpInfoByBatch',
            params,
        })
    },
    // 导入记录-确定上账
    doImportLogUpInfoByBatch(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/order/apr/doImportLogUpInfoByBatch',
            data: params,
        })
    },
     //导入记录-下载导入数据
     excelImportLogListByBatch(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/order/apr/excelImportLogListByBatch',
            params,
            responseType: 'blob'
        })
    },
    // 查询-强制ID校验设置
    getCompanyCompelValidAppleId(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/getCompanyCompelValidAppleId',
            params,
        })
    },
    // 查询-抢单报价时长设置
    getCompanyLimitExclusive(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/getCompanyLimitExclusive',
            params,
        })
    },
    // 设置-强制ID校验设置
    setCompanyCompelValidAppleId(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/setCompanyCompelValidAppleId',
            data: params,
        })
    },
    // 设置-抢单报价时长设置
    setCompanyLimitExclusive(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/setCompanyLimitExclusive',
            data: params,
        })
    },
    // 查询-门店首页标签下拉列表
    getStorePcTagSelect(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/storePc/statistics/getStoreTagSelect',
            params,
        })
    },
    // 黑名单商户列表
    blackExternalMerchantList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/blacklist/blackExternalMerchantList',
            data: params,
        })
    },
    // 添加黑名单商户
    addExternalMerchantBlack(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/blacklist/addExternalMerchantBlackList',
            data: params,
        })
    },
    // 添加黑名单门店或者店员
    addBlackMsgList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/blacklist/addBlackMsgList',
            data: params,
        })
    },
    // 外部商户列表
    externalMerchantList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/blacklist/externalMerchantList',
            data: params,
        })
    },
    // 黑名单门店或店员信息列表
    riskValueList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/blacklist/riskValueList',
            data: params,
        })
    },
    // 移除黑名单门店或店员
    deleteRiskValue(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/company/blacklist/deleteRiskValue',
            data: params,
        })
    },
    // 查询-外部商户下拉列表
    selectOutCompanyList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/company/blacklist/selectOutCompanyList',
            params,
        })
    },
    // 获取渠道扣款模板列表
    getTemplateList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/matchChannel/getTemplateList',
            params,
        })
    },
    // 获取服务费模板
    getServeTemplateList(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/matchChannel/getServeTemplateList',
            params,
        })
    },
    // 保存撮合扣款模板
    saveTemplate(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matchChannel/saveTemplate',
            data: params,
        })
    },
    // 保存服务费模板
    saveServeTemplateList(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/matchChannel/saveServeTemplateList',
            data: params,
        })
    },
    // 批量禁用或启用职员
    staffOptEnableBatch(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/staffOptEnableBatch',
            data: params,
        })
    },
    // 启用或禁用修改报价权限
    staffCanUpdateOtherQuote(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/staffCanUpdateOtherQuote',
            data: params,
        })
    },
    // 启用或禁用标记库管
    staffIsStorageKeeper(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/staffIsStorageKeeper',
            data: params,
        })
    },
    // 查看外部推单余额最低金额
    getPushOutOrderMinAmount(params) {
        return request({
            method: 'GET',
            url: '/platform/pc/recycle/merchant/not-push-out-order-min-amount-get',
            params,
        })
    },
    // 设置外部推单余额最低金额
    setPushOutOrderMinAmount(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/recycle/merchant/not-push-out-order-min-amount-set',
            data: params,
        })
    },
}
// --------------------------------------------------------- end -------------------------------------------------------------->
export default menu