<template>
  <div class="GlobalInfoBar">
    <div v-if="type === 'common'" class="info-bar-container">
      <div class="info-bar">
        <!--商店image-->
        <img
          v-if="icon === 'shop'"
          class="info-bar_icon"
          src="@/assets/images/icon_sjlb_@2x.png"
          alt
        />
        <!--回收image-->
        <img
          v-if="icon === 'recycle'"
          class="info-bar_icon"
          src="@/assets/images/icon_hs__@2x.png"
          alt
        />
        <slot></slot>
        <div class="info-bar_content">
          <h5>{{ title }}</h5>
          <p>
            {{ content
            }}<span v-if="contentOn" class="text-on">{{ contentOn }}</span>
          </p>
        </div>
        <slot name="right"></slot>
      </div>
    </div>
    <div v-if="type === 'orderMa'" class="code_content">
      <div class="info-bar-container_oder">
        <div class="info-bar">
          <!--商店image-->
          <img
            v-if="icon === 'shop'"
            class="info-bar_icon"
            src="@/assets/images/icon_sjlb_@2x.png"
            alt
          />
          <!--回收image-->
          <img
            v-if="icon === 'recycle'"
            class="info-bar_icon"
            src="@/assets/images/icon_hs__@2x.png"
            alt
          />
          <slot></slot>
          <div class="info-bar_content">
            <h5>{{ title }}</h5>
            <p>
              {{ content
              }}<span v-if="contentOn" class="text-on">{{ contentOn }}</span>
            </p>
          </div>
          <slot name="right"></slot>
        </div>
      </div>
      <div class="right_code">
        <div class="rc_left">
          <p>平台通用验证码</p>
          <p class="foot_text">(用于门店退单)</p>
        </div>
        <div class="rc_right">
          <div class="rr_top">
            <span id="copycode">{{ verificationcode.code }}</span>
            <div class="copy_code" @click="copy('copycode')">复制</div>
          </div>
          <p class="foot_text">每10分钟更新一次</p>
        </div>
      </div>
    </div>
    <div v-if="type === 'quickRemark'" class="info-bar-container">
      <div class="info-bar" style="justify-content: space-between">
        <div style="display: flex;align-items: center">
          <!--商店image-->
          <img
            v-if="icon === 'shop'"
            class="info-bar_icon"
            src="@/assets/images/icon_sjlb_@2x.png"
            alt
          />
          <!--回收image-->
          <img
            v-if="icon === 'recycle'"
            class="info-bar_icon"
            src="@/assets/images/icon_hs__@2x.png"
            alt
          />
          <slot></slot>
          <div class="info-bar_content">
            <h5>{{ title }}</h5>
            <p>
              {{ content
              }}<span v-if="contentOn" class="text-on">{{ contentOn }}</span>
            </p>
          </div>
        </div>
        <slot name="right"></slot>
      </div>
    </div>
    <div v-if="type === 'hint'" class="info-bar-container hint-container">
      <div class="info-bar">
        <img class="info-bar_icon" src="@/assets/images/icon_th@2x.png" alt />
        <div class="info-bar_content">
          <h5>{{ title }}</h5>
          <p>{{ content }}</p>
        </div>
        <div class="iphone-info">
          <!--<img class="iphone-info_icon" src="@/assets/images/icon_th@2x.png" alt />-->
          <img
            class="iphone-info_icon"
            src="@/assets/images/icon_lxgl@2x.png"
            alt
          />
          <span>联系管理员</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "GlobalInfoBar",
  props: {
    title: String,
    content: String,
    contentOn: String,
    // type: common/hint
    type: {
      type: String,
      default: "common",
    },
    // icon: shop/recycle
    icon: {
      type: String,
      default: "shop",
    },
  },
  data() {
    return {
      verificationcode: {
        code: "",
        surplusSecond: 0,
      },
    };
  },
  created() {
    if (this.type === "orderMa") {
      this.generalCod();
    }
  },
  methods: {
    generalCod() {
      _api.generalCode().then((res) => {
        if (res.code == 1) {
          this.verificationcode = res.data;
        }
      });
    },
    copy(e) {
      const spanText = document.getElementById(e).innerText;
      const oInput = document.createElement("input");
      oInput.value = spanText;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message.success("复制成功");
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">

.right_code {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 10px;
  width: 320px;
  height: 66px;
  background: #feeaeb;
  border-radius: 8px 33px 33px 8px;

  .rc_left {
    font-size: 16px;
    font-family: FZLanTingHei-B-GBK;
    font-weight: bold;
    color: #333333;
  }

  .foot_text {
    text-align: center;
    font-size: 12px;
    font-family: FZLanTingHei-R-GBK;
    font-weight: 400;
    color: #333333;
  }

  .rr_top {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: FZLanTingHei-B-GBK;
    font-weight: bold;
    color: #ff4949;
  }

  .copy_code {
    cursor: pointer;
    margin-left: 5px;
    text-align: center;
    width: 30px;
    height: 16px;
    background: #ff4949;
    border-radius: 3px;
    font-size: 12px;
    font-family: FZLanTingHeiS-R-GB;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
  }
}

.code_content {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.info-bar-container {
  margin-bottom: 20px;

  .info-bar {
    padding: 10px 20px 10px 20px;
    background: #e4ecfd;
    border-radius: 33px;
    display: flex;
    align-items: center;

    &_icon {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }

    &_content {
      > h5 {
        font-size: 18px;
        font-weight: 700;
        color: #242d3e;
        line-height: 20px;
        margin-bottom: 5px;
      }

      > p {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
    }
  }

  .text-on {
    color: #ff687b;
  }
}

.info-bar-container_oder {
  flex: 1;

  .info-bar {
    padding: 10px 20px 10px 20px;
    background: #e4ecfd;
    border-radius: 33px 0 0 33px;
    display: flex;
    align-items: center;

    &_icon {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }

    &_content {
      > h5 {
        font-size: 18px;
        font-weight: 700;
        color: #242d3e;
        line-height: 20px;
        margin-bottom: 5px;
      }

      > p {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
    }
  }

  .text-on {
    color: #ff687b;
  }
}

.hint-container {
  .info-bar {
    padding: 4px 10px 4px 10px;
    background: #ffebeb;
    border-radius: 25px;
    position: relative;

    &_icon {
      width: 22px;
      height: 22px;
      margin-right: 11px;
    }

    &_content {
      > h5 {
        font-size: 14px;
        font-weight: 400;
        color: #ff8080;
        margin-bottom: 0;
      }

      > p {
        font-size: 14px;
        font-weight: 400;
        color: #242d3e;
      }
    }

    .iphone-info {
      position: absolute;
      right: 22px;
      font-size: 14px;
      font-weight: 400;
      color: #242d3e;
      display: flex;
      align-items: center;

      &_icon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
    }
  }
}
</style>
