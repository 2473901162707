import Layout from '@/layout/layout'

const elementRouter = {
  path: '/merchant',
  component: Layout,
  redirect: 'noRedirect',
  name: '第三方回收商',
  children: [
    {
      path: 'list',
      component: () => import('@/views/merchant/views/list/index'),
      name: '商家列表',
      meta: { title: '商家列表', noCache: true }
    },
    {
      path: 'add',
      component: () => import('@/views/merchant/views/add/index'),
      name: '添加商家',
      meta: { title: '添加商家', noCache: true }
    },
    {
      path: 'apply',
      component: () => import('@/views/merchant/views/apply/index'),
      name: '入驻申请',
      meta: { title: '入驻申请', noCache: true }
    },
    {
      path: 'queryDetails',
      component: () => import('@/views/merchant/views/queryDetails/index'),
      name: '串号查询明细',
      meta: { title: '串号查询明细', noCache: true }
    },
    {
      path: 'reviewSettings',
      component: () => import('@/views/merchant/views/reviewSettings/index'),
      name: '审单报价设置',
      meta: { title: '审单报价设置', noCache: true }
    }
  ]
}

export default elementRouter
