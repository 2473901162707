import Layout from '@/layout/layout'

const systemManageRouter = {
  path: '/systemManage',
  component: Layout,
  redirect: 'noRedirect',
  name: '系统管理',
  children: [
    {
      path: 'RolePurview',
      component: () => import('@/views/systemManage/RolePurview/index'),
      name: '角色权限',
      meta: { title: '角色权限', noCache: true }
    },
    {
      path:'StaffManage',
      component: () => import('@/views/systemManage/StaffManage/index'),
      name: '职员管理',
      meta: { title: '职员管理', noCache: true }
    },
    {
      path:'MenuManage',
      component: () => import('@/views/systemManage/MenuManage/index'),
      name: '菜单管理',
      meta: { title: '菜单管理', noCache: true }
    },
    {
      path:'StaffAdd',
      component: () => import('@/views/systemManage/add/StaffAdd'),
      name: '添加职员',
      meta: { title: '添加职员', noCache: true }
    },
    {
      path:'RoleAdd',
      component: () => import('@/views/systemManage/add/RoleAdd'),
      name: '添加角色',
      meta: { title: '添加角色', noCache: true }
    },
    {
      path:'Resources',
      component: () => import('@/views/systemManage/Resources/index'),
      name: '资源配置',
      meta: { title: '资源配置', noCache: true }
    },
    {
      path:'XcxEdition',
      component: () => import('@/views/systemManage/XcxEdition/index'),
      name: '小程序版本更新',
      meta: { title: '小程序版本更新', noCache: true }
    },
    {
      path:'PublicNumberManagement',
      component: () => import('@/views/systemManage/PublicNumberManagement/index'),
      name: '公众号消息管理',
      meta: { title: '公众号消息管理', noCache: true }
    },
    {
      path:'certificateManagement',
      component: () => import('@/views/systemManage/certificateManagement/index'),
      name: '证书到期提醒',
      meta: { title: '证书到期提醒', noCache: true }
    },
    {
      path:'log',
      component: () => import('@/views/systemManage/log/index'),
      name: '操作日志',
      meta: { title: '操作日志', noCache: true }
    },
    {
      path:'operate',
      component: () => import('@/views/systemManage/operate/index'),
      name: '运营后台权限管理',
      meta: { title: '运营后台权限管理', noCache: true }
    }
  ]
}

export default systemManageRouter
